import { Link, Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useGetMostProductsQuery } from './statApi';

function MostProducts() {
  const { data, isFetching } = useGetMostProductsQuery();

  return (
    <Skeleton isLoaded={!isFetching}>
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Domain</Th>
              <Th isNumeric>Products</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.map(({ domain, total_products }, index) => (
                <Tr key={domain}>
                  <Td>{index + 1}</Td>
                  <Td>
                    <Link href={`https://${domain}`} isExternal>
                      {domain}
                    </Link>
                  </Td>
                  <Td isNumeric>{Number(total_products).toLocaleString()}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Skeleton>
  );
}

export default MostProducts;
