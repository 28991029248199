import {
  Button,
  ButtonGroup,
  Divider,
  Heading,
  SimpleGrid,
  Center,
  useToast,
  Box,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import MultiSelectList from '../multiSelectList/MultiSelectList';
import {
  useBulkWebsiteBrandsMutation,
  useGetAllBrandsQuery,
  useGetGlobalBrandsQuery,
  useGetWebsiteBrandsQuery,
} from './brandApi';
import { brandListValidationSchema } from './validationSchema';
import { IBulkWebsiteBrands, IKeyValue } from './types';
import { IDataType } from '../multiSelectList/types';
import { useGetWebsitesQuery } from '../website/websiteApi';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';
import useMap from '../../hooks/useMap';
import BrandFilterModal from './BrandFilterModal';

function BrandsForm() {
  const toast = useToast();
  const params = useParams();
  const websiteId = params.websiteId ? Number(params.websiteId) : undefined;
  const { data: globalBrands } = useGetGlobalBrandsQuery();
  const { data: website, isLoading: isLoadingWebsiteBrands } = useGetWebsiteBrandsQuery(websiteId ?? skipToken);
  const { data: websites, isLoading: isLoadingWebsites } = useGetWebsitesQuery(undefined, {
    skip: websiteId !== undefined,
  });
  const { data: brands, isLoading: isLoadingBrands } = useGetAllBrandsQuery();
  const [bulkWebsiteBrands, { isLoading: isSubmitting }] = useBulkWebsiteBrandsMutation();
  const isLoading = isLoadingWebsiteBrands || isLoadingBrands || isLoadingWebsites;
  const enabledList = useMap();
  const disabledList = useMap();
  const websiteList = useMap();
  const brandList: IDataType[] = useMemo(
    () =>
      brands
        ? brands.map(({ aaia_brand_code, name }) => ({ key: aaia_brand_code, value: `${name} (${aaia_brand_code})` }))
        : [],
    [brands],
  );

  useEffect(() => {
    if (brands && website && !enabledList.initialized && !disabledList.initialized) {
      const disabledBrandCodes = website.disabled.map(({ aaia_brand_code }) => aaia_brand_code);

      brandList.forEach(({ key, value }) => {
        if (disabledBrandCodes.includes(key)) {
          disabledList.addItem(key, value);
        } else if (website) {
          enabledList.addItem(key, value);
        }
      });

      enabledList.setInitialized(true);
      disabledList.setInitialized(true);
    }
  }, [website, brands, brandList, enabledList, disabledList]);

  const {
    handleSubmit,
    reset: resetForm,
    setValue,
  } = useForm<IBulkWebsiteBrands>({
    resolver: yupResolver(brandListValidationSchema),
  });

  const addEnabledItem = (key: string, value: string) => {
    disabledList.removeItem(key);
    enabledList.addItem(key, value);
  };

  const removeEnabledItem = (key: string) => {
    const value = enabledList.items.get(key);

    if (value) {
      enabledList.removeItem(key);
      disabledList.addItem(key, value);
    }
  };

  const addDisabledItem = (key: string, value: string) => {
    enabledList.removeItem(key);
    disabledList.addItem(key, value);
  };

  const removeDisabledItem = (key: string) => {
    const value = disabledList.items.get(key);

    if (value) {
      disabledList.removeItem(key);
      enabledList.addItem(key, value);
    }
  };

  const disableGlobalBrands = () => {
    if (globalBrands) {
      globalBrands.disabled.forEach(({ aaia_brand_code, name }) => {
        addDisabledItem(aaia_brand_code, `${name} (${aaia_brand_code})`);
      });
    }
  };

  const enableBrandFilter = (filterBrands: IKeyValue) => {
    Object.entries(filterBrands).forEach(([key, value]) => {
      addEnabledItem(key, value);
    });
  };

  const disableBrandFilter = (filterBrands: IKeyValue) => {
    Object.entries(filterBrands).forEach(([key, value]) => {
      addDisabledItem(key, value);
    });
  };

  useEffect(() => {
    setValue(
      'enabled',
      Array.from(enabledList.items[Symbol.iterator]()).map(([key, value]) => ({ code: key, name: value })),
    );
  }, [setValue, enabledList]);

  useEffect(() => {
    setValue(
      'disabled',
      Array.from(disabledList.items[Symbol.iterator]()).map(([key, value]) => ({ code: key, name: value })),
    );
  }, [setValue, disabledList]);

  useEffect(() => {
    if (websiteId && website) {
      setValue('websites', [{ id: websiteId.toString(), domain: website.website.domain }]);
    } else {
      setValue(
        'websites',
        Array.from(websiteList.items[Symbol.iterator]()).map(([key, value]) => ({ id: key, domain: value })),
      );
    }
  }, [websiteId, website, websiteList, setValue]);

  const onReset = () => {
    resetForm();

    if (!websiteId) {
      enabledList.clearItems();
      disabledList.clearItems();
      websiteList.clearItems();
    }
  };

  const onSubmit = async (values: IBulkWebsiteBrands) => {
    try {
      const { error } = await bulkWebsiteBrands(values).unwrap();

      if (error) {
        toast({
          title: error,
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          title: 'The website brands have been updated!',
          status: 'success',
          isClosable: true,
          position: 'top',
        });

        onReset();
      }
    } catch (error) {
      toast({
        title: 'Failed to update the website brands!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <>
      {isLoading && (
        <Center>
          <ThemedSyncLoader />
        </Center>
      )}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
            <Box>
              <Flex gap="3" mt="6">
                <Heading as="h2" fontSize="26px">
                  Brands to Enable
                </Heading>
                <Spacer />
                <BrandFilterModal confirmAction={enableBrandFilter} />
              </Flex>
              <Divider my="3" />
              <MultiSelectList
                searchable
                allowSelectAll
                leftHeading="Select Brands"
                rightHeading="Brands to Enable"
                data={brandList}
                items={enabledList.items}
                addItem={addEnabledItem}
                removeItem={removeEnabledItem}
                clearItems={enabledList.clearItems}
              />
            </Box>
            <Box>
              <Flex gap="3" mt="6">
                <Heading as="h2" fontSize="26px">
                  Brands to Disable
                </Heading>
                <Spacer />
                {globalBrands && (
                  <Button colorScheme="red" size="sm" onClick={disableGlobalBrands}>
                    Disable Global Brands
                  </Button>
                )}
                <BrandFilterModal confirmAction={disableBrandFilter} />
              </Flex>
              <Divider my="3" />
              <MultiSelectList
                searchable
                allowSelectAll
                leftHeading="Select Brands"
                rightHeading="Brands to Disable"
                data={brandList}
                items={disabledList.items}
                addItem={addDisabledItem}
                removeItem={removeDisabledItem}
                clearItems={disabledList.clearItems}
              />
            </Box>
            {!websiteId && websites && (
              <Box>
                <Heading as="h2" fontSize="26px" mt="6">
                  Apply Changes to Websites
                </Heading>
                <Divider my="3" />
                <MultiSelectList
                  searchable
                  allowSelectAll
                  leftHeading="Select Websites"
                  rightHeading="Apply Changes to Websites"
                  data={
                    websites
                      ? websites.map(({ id, domain }) => ({
                          key: id.toString(),
                          value: domain,
                        }))
                      : []
                  }
                  items={websiteList.items}
                  addItem={websiteList.addItem}
                  removeItem={websiteList.removeItem}
                  clearItems={websiteList.clearItems}
                />
              </Box>
            )}
          </SimpleGrid>
          <ButtonGroup mt="6" spacing="3">
            <Button colorScheme="green" isLoading={isLoading || isSubmitting} type="submit">
              Submit
            </Button>
            <Button colorScheme="gray" disabled={isLoading || isSubmitting} type="button" onClick={onReset}>
              Reset
            </Button>
          </ButtonGroup>
        </form>
      )}
    </>
  );
}

export default BrandsForm;
