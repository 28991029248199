export type PermissionType =
  | 'staging_deployments'
  | 'production_deployments'
  | 'development_deployments'
  | 'manage_system_users'
  | 'manage_site_users'
  | 'manage_disabled_brands'
  | 'manage_bulk_brands'
  | 'manage_unavailable_brands'
  | 'manage_global_brands'
  | 'manage_global_instant_rebates'
  | 'view_websites'
  | 'create_websites'
  | 'run_scripts'
  | 'client_map'
  | 'change_domains';

interface IPermission {
  name: PermissionType;
  route: string;
  label: string;
}

export const permissions = [
  {
    name: 'staging_deployments',
    route: '/deployment/stage',
    label: 'Staging Deployments',
  },
  {
    name: 'production_deployments',
    route: '/deployment/prod',
    label: 'Production Deployments',
  },
  {
    name: 'development_deployments',
    route: '/deployment/dev',
    label: 'Development Deployments',
  },
  {
    name: 'manage_system_users',
    route: '/system-user',
    label: 'Manage System Users',
  },
  {
    name: 'manage_site_users',
    route: '/site-user',
    label: 'Manage Site Users',
  },
  {
    name: 'manage_bulk_brands',
    route: '/brand/bulk',
    label: 'Bulk Disable Brands',
  },
  {
    name: 'manage_unavailable_brands',
    route: '/brand/unavailable',
    label: 'Manage Unavailable Brands',
  },
  {
    name: 'manage_global_brands',
    route: '/brand/global',
    label: 'Manage Global Disabled Brands',
  },
  {
    name: 'manage_global_instant_rebates',
    route: '/global-instant-rebate',
    label: 'Manage Global Instant Rebates',
  },
  {
    name: 'manage_disabled_brands',
    route: '/brand',
    label: 'Disable Website Brands',
  },
  {
    name: 'create_websites',
    route: '/website/create',
    label: 'Website Builder',
  },
  {
    name: 'change_domains',
    route: '/website/change',
    label: 'Change Domains',
  },
  {
    name: 'view_websites',
    route: '/website',
    label: 'View Websites',
  },
  {
    name: 'run_scripts',
    route: '/script',
    label: 'Run Scripts',
  },
  {
    name: 'client_map',
    route: '/client-map',
    label: 'View Client Map',
  },
] as IPermission[];
