import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { IDeployment } from '../deployment/types';
import { IWebsite } from '../website/types';
import { IOrders, IOrdersRequest, ITotals } from './types';

export const statApi = createApi({
  reducerPath: 'statApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['Totals', 'RecentDeployments', 'MostProducts', 'Orders'],
  endpoints: (builder) => ({
    getTotals: builder.query<ITotals, void>({
      query: () => ({
        url: 'stat/totals',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Totals', id: 'LIST' }],
    }),
    getRecentDeployments: builder.query<IDeployment[], void>({
      query: () => ({
        url: 'stat/deployments',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'RecentDeployments', id: 'LIST' }],
    }),
    getMostProducts: builder.query<IWebsite[], void>({
      query: () => ({
        url: 'stat/products',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'MostProducts', id: 'LIST' }],
    }),
    getOrders: builder.query<IOrders, IOrdersRequest>({
      query: (params) => ({
        url: 'stat/orders',
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Orders', id: 'LIST' }],
    }),
  }),
});

export const { useGetTotalsQuery, useGetRecentDeploymentsQuery, useGetMostProductsQuery, useGetOrdersQuery } = statApi;
