import { Input, InputProps } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

interface IProps extends InputProps {
  value: string | number;
  handleChange: (value: string | number) => void;
  debounce?: number;
}

function DebouncedInput({ value: initialValue, handleChange, debounce = 500, ...props }: IProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, handleChange, debounce]);

  return <Input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}

DebouncedInput.defaultProps = {
  debounce: 500,
};

export default DebouncedInput;
