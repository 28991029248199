import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import {
  IBrand,
  IBulkWebsiteBrands,
  IGetDisabledBrands,
  IUpdateDisabledBrands,
  IResponse,
  IGetWebsiteBrands,
  IBrandFilter,
  IDeleteResponse,
} from './types';

export const brandApi = createApi({
  reducerPath: 'brandApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['BrandList', 'WebsiteBrands', 'BrandFilters'],
  endpoints: (builder) => ({
    getAllBrands: builder.query<IBrand[], void>({
      query: () => ({
        url: 'brand/list',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'BrandList', id } as const)), { type: 'BrandList', id: 'LIST' }]
          : [{ type: 'BrandList', id: 'LIST' }],
    }),
    getWebsiteBrands: builder.query<IGetWebsiteBrands, number>({
      query: (id) => ({
        url: `brand/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'WebsiteBrands', id }],
    }),
    bulkWebsiteBrands: builder.mutation<IResponse, IBulkWebsiteBrands>({
      query: (data) => ({
        url: 'brand/bulk',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['WebsiteBrands'],
    }),
    getGlobalBrands: builder.query<IGetDisabledBrands, void>({
      query: () => ({
        url: 'brand/global',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'WebsiteBrands', id: 'GLOBAL' }],
    }),
    updateGlobalBrands: builder.mutation<IResponse, IUpdateDisabledBrands>({
      query: (data) => ({
        url: 'brand/global',
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'WebsiteBrands', id: 'GLOBAL' }],
    }),
    getUnavailableBrands: builder.query<IGetDisabledBrands, void>({
      query: () => ({
        url: 'brand/unavailable',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'WebsiteBrands', id: 'UNAVAILABLE' }],
    }),
    updateUnavailableBrands: builder.mutation<IResponse, IUpdateDisabledBrands>({
      query: (data) => ({
        url: 'brand/unavailable',
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'WebsiteBrands', id: 'UNAVAILABLE' }],
    }),
    getBrandFilters: builder.query<IBrandFilter[], void>({
      query: () => ({
        url: 'brand-filter',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'BrandFilters', id } as const)), { type: 'BrandFilters', id: 'LIST' }]
          : [{ type: 'BrandFilters', id: 'LIST' }],
    }),
    getBrandFilter: builder.query<IBrandFilter, string>({
      query: (id) => ({
        url: `brand-filter/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'BrandFilters', id }],
    }),
    createBrandFilter: builder.mutation<IBrandFilter, Partial<IBrandFilter>>({
      query: (data) => ({
        url: 'brand-filter',
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'BrandFilters', id: 'LIST' }],
    }),
    updateBrandFilter: builder.mutation<IBrandFilter, Partial<IBrandFilter>>({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `brand-filter/${id}`,
          method: 'PUT',
          data: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'BrandFilters', id }],
    }),
    deleteBrandFilter: builder.mutation<IDeleteResponse, number>({
      query: (id) => ({
        url: `brand-filter/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'BrandFilters', id }],
    }),
  }),
});

export const {
  useGetAllBrandsQuery,
  useGetWebsiteBrandsQuery,
  useBulkWebsiteBrandsMutation,
  useGetGlobalBrandsQuery,
  useUpdateGlobalBrandsMutation,
  useGetUnavailableBrandsQuery,
  useUpdateUnavailableBrandsMutation,
  useGetBrandFiltersQuery,
  useGetBrandFilterQuery,
  useCreateBrandFilterMutation,
  useUpdateBrandFilterMutation,
  useDeleteBrandFilterMutation,
} = brandApi;
