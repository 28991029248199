import { configureStore } from '@reduxjs/toolkit';
import { authApi } from '../features/auth/authApi';
import { systemUserApi } from '../features/systemUser/systemUserApi';
import { websiteApi } from '../features/website/websiteApi';
import { siteUserApi } from '../features/siteUser/siteUserApi';
import { scriptApi } from '../features/script/scriptApi';
import { brandApi } from '../features/brand/brandApi';
import { deploymentApi } from '../features/deployment/deploymentApi';
import { statApi } from '../features/stat/statApi';
import { locationApi } from '../features/clientMap/locationApi';
import { globalInstantRebateApi } from '../features/instantRebate/globalInstantRebateApi';
import { serverApi } from '../features/server/serverApi';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [statApi.reducerPath]: statApi.reducer,
    [systemUserApi.reducerPath]: systemUserApi.reducer,
    [websiteApi.reducerPath]: websiteApi.reducer,
    [siteUserApi.reducerPath]: siteUserApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [scriptApi.reducerPath]: scriptApi.reducer,
    [deploymentApi.reducerPath]: deploymentApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [globalInstantRebateApi.reducerPath]: globalInstantRebateApi.reducer,
    [serverApi.reducerPath]: serverApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      statApi.middleware,
      systemUserApi.middleware,
      websiteApi.middleware,
      siteUserApi.middleware,
      brandApi.middleware,
      scriptApi.middleware,
      deploymentApi.middleware,
      locationApi.middleware,
      globalInstantRebateApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
