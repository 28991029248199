import { useState } from 'react';

interface IHook {
  items: Map<string, string>;
  setItems: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  initialized: boolean;
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  addItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
  clearItems: () => void;
}

export default (initial?: Map<string, string>): IHook => {
  const [items, setItems] = useState(() => initial || new Map<string, string>());
  const [initialized, setInitialized] = useState(false);

  const addItem = (key: string, value: string) => {
    if (!items.has(key)) {
      setItems((prev) => {
        const newItems = new Map<string, string>(prev);
        newItems.set(key, value);
        return newItems;
      });
    }
  };

  const removeItem = (key: string) => {
    setItems((prev) => {
      const newItems = new Map<string, string>(prev);
      newItems.delete(key);
      return newItems;
    });
  };

  const clearItems = () => {
    setItems((prev) => {
      const newItems = new Map<string, string>(prev);
      newItems.clear();
      return newItems;
    });
  };

  return { items, setItems, initialized, setInitialized, addItem, removeItem, clearItems };
};
