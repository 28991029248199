import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { IServer } from './types';

export const serverApi = createApi({
  reducerPath: 'serverApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['Servers'],
  endpoints: (builder) => ({
    getServers: builder.query<IServer[], void | undefined>({
      query: () => ({
        url: 'server',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ server_id }) => ({ type: 'Servers', id: server_id } as const)),
              { type: 'Servers', id: 'LIST' },
            ]
          : [{ type: 'Servers', id: 'LIST' }],
    }),
  }),
});

export const { useGetServersQuery } = serverApi;
