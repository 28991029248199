import { Box, Flex, Text, Button, Spacer, IconButton, Tooltip, useColorMode, useToast } from '@chakra-ui/react';
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import CustomButtonGroup from '../buttonGroup/CustomButtonGroup';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';
import ConfirmDeleteModal from '../confirmDeleteModal/ConfirmDeleteModal';
import { useDeleteSystemUserMutation, useGetSystemUsersQuery } from './systemUserApi';
import { useLoginQuery } from '../auth/authApi';
import ContentContainer from '../layout/ContentContainer';

function SystemUsers() {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { data: currentUser } = useLoginQuery();
  const { data: systemUsers, isLoading } = useGetSystemUsersQuery();
  const [deleteSystemUser] = useDeleteSystemUserMutation();

  return (
    <ContentContainer heading="System Users">
      <CustomButtonGroup mb="6">
        <Button as={Link} to="/system-user/add" colorScheme="green">
          Add System User
        </Button>
      </CustomButtonGroup>
      <ThemedSyncLoader center loading={isLoading} />
      {systemUsers && (
        <Flex direction="column" gap={3} mb={3}>
          {systemUsers
            .filter(({ id }) => id !== currentUser?.authenticatedUser.id)
            .map(({ id, first_name, last_name }) => {
              const deleteAction = async () => {
                const res = await deleteSystemUser(id).unwrap();
                if (res.success) {
                  toast({
                    title: `Successfully deleted ${first_name} ${last_name}!`,
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                  });
                } else {
                  toast({
                    title: `Failed to delete ${first_name} ${last_name}!`,
                    status: 'error',
                    isClosable: true,
                    position: 'top',
                  });
                }
              };

              return (
                <Flex
                  key={id}
                  bg={colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.100'}
                  px={3}
                  py={1}
                  gap={3}
                  alignItems="center"
                  borderRadius="lg"
                >
                  <Box>
                    <Text>{`${first_name} ${last_name}`}</Text>
                  </Box>
                  <Spacer />
                  <Tooltip label="Edit System User" placement="left" hasArrow>
                    <IconButton
                      as={Link}
                      to={`/system-user/${id}`}
                      variant="ghost"
                      colorScheme="blue"
                      aria-label="Edit System User"
                      icon={<FontAwesomeIcon icon={faPenToSquare} />}
                      data-testid={`edit-user-${id}`}
                    />
                  </Tooltip>
                  <ConfirmDeleteModal
                    iconButton
                    buttonLabel="Delete System User"
                    modalHeading={`Delete ${first_name} ${last_name}?`}
                    deleteAction={deleteAction}
                    testId={`delete-user-${id}`}
                  />
                </Flex>
              );
            })}
        </Flex>
      )}
    </ContentContainer>
  );
}

export default SystemUsers;
