import { Center, Grid, GridItem, useColorMode } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { useGetCsrfCookieQuery, useLoginQuery } from '../auth/authApi';
import MobileMenu from '../sidebarMenu/MobileMenu';
import SidebarMenu from '../sidebarMenu/SidebarMenu';
import ThemedPuffLoader from '../loader/ThemedPuffLoader';
import { getServerUrl, isDevEnv } from '../../envUtils';

function Layout() {
  const { colorMode } = useColorMode();
  const { isSuccess: isSuccessCookie, isFetching: isFetchingCookie } = useGetCsrfCookieQuery();
  const { isSuccess: isSuccessLogin, isFetching: isFetchingLogin } = useLoginQuery(undefined, {
    skip: !isSuccessCookie,
  });

  const redirectToLogin = () => {
    window.location.href = isDevEnv()
      ? `${getServerUrl()}login/auth`
      : 'https://accounts.theaamgroup.com/portal/auth/155';
  };

  if (isFetchingCookie || isFetchingLogin) {
    return (
      <Center h="100vh" w="100vw">
        <ThemedPuffLoader data-testid="isLoading" />
      </Center>
    );
  }

  if (!isSuccessLogin) {
    redirectToLogin();

    return (
      <Center h="100vh" w="100vw" data-testid="logged-out-content">
        <ThemedPuffLoader data-testid="isLoading" />
      </Center>
    );
  }

  return (
    <Grid
      data-testid="logged-in-content"
      h="100vh"
      w="100%"
      templateAreas={{
        base: `
            "nav"
            "main"
        `,
        lg: `"nav main"`,
      }}
      gridTemplateRows={{
        base: 'auto 1fr',
        lg: '1fr',
      }}
      gridTemplateColumns={{
        base: '1fr',
        lg: '250px 1fr',
      }}
    >
      <GridItem area="nav" rounded="md" p="6" position={{ base: 'relative', lg: 'fixed' }}>
        <SidebarMenu display={{ base: 'none', lg: 'flex' }} />
        <MobileMenu display={{ base: 'flex', lg: 'none' }} />
      </GridItem>
      <GridItem area="main" bg={colorMode === 'dark' ? 'gray.900' : 'gray.200'} p="6">
        <Outlet />
      </GridItem>
    </Grid>
  );
}

export default Layout;
