import {
  SimpleGrid,
  ButtonGroup,
  Button,
  useToast,
  Box,
  Heading,
  Divider,
  useDisclosure,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import ContentContainer from '../../layout/ContentContainer';
import AllScriptsButton from '../AllScriptsButton';
import { useDeleteBrandsMutation } from '../scriptApi';
import MultiSelectList from '../../multiSelectList/MultiSelectList';
import { useGetAllBrandsQuery } from '../../brand/brandApi';
import { IDeleteBrandsRequest } from '../types';
import usageGroupValidation from '../validationSchema';
import useMap from '../../../hooks/useMap';
import { useGetWebsitesQuery } from '../../website/websiteApi';

function DeleteBrands() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: brands, isLoading: isLoadingBrands } = useGetAllBrandsQuery();
  const { data: websites, isLoading: isLoadingWebsites } = useGetWebsitesQuery();
  const [deleteBrands, { data, isUninitialized, isLoading: isPosting, isSuccess, isError, reset: resetData }] =
    useDeleteBrandsMutation();
  const isLoading = isLoadingBrands || isLoadingWebsites || isPosting;
  const brandList = useMap();
  const websiteList = useMap();
  const isValid = brandList.items.size > 0 && websiteList.items.size > 0;

  const {
    handleSubmit,
    reset: resetForm,
    formState: { isSubmitting },
    setValue,
  } = useForm<IDeleteBrandsRequest>({
    resolver: yupResolver(usageGroupValidation),
  });

  useEffect(() => {
    setValue(
      'brands',
      Array.from(brandList.items[Symbol.iterator]()).map(([key, value]) => ({ code: key, name: value })),
    );
  }, [brandList.items, setValue]);

  useEffect(() => {
    setValue(
      'websites',
      Array.from(websiteList.items[Symbol.iterator]()).map(([key, value]) => ({ id: key, domain: value })),
    );
  }, [websiteList.items, setValue]);

  const onReset = () => {
    resetForm();
    resetData();
    brandList.clearItems();
    websiteList.clearItems();
  };

  const onSubmit = async (values: IDeleteBrandsRequest) => {
    try {
      const res = await deleteBrands(values).unwrap();

      if (res.batch_id) {
        const message: React.ReactNode = `Brand deletion started! You will receive an email when it completes.`;

        toast({
          title: message,
          status: 'success',
          isClosable: true,
          position: 'top',
        });

        onClose();
        onReset();
      } else if (res.error) {
        toast({
          title: res.error,
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      onClose();
      resetData();

      toast({
        title: `An internal server error occurred.`,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <ContentContainer heading="Delete Brands">
      <AllScriptsButton />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <Box>
            <Heading as="h2" fontSize="26px" mt="6">
              Brands to Delete
            </Heading>
            <Divider my="3" />
            <MultiSelectList
              searchable
              allowSelectAll={false}
              leftHeading="Select Brands"
              rightHeading="Selected Brands"
              data={
                brands
                  ? brands.map(({ aaia_brand_code, name }) => ({
                      key: aaia_brand_code,
                      value: `${name} (${aaia_brand_code})`,
                    }))
                  : []
              }
              items={brandList.items}
              addItem={brandList.addItem}
              removeItem={brandList.removeItem}
              clearItems={brandList.clearItems}
            />
          </Box>
          {websites && (
            <Box>
              <Heading as="h2" fontSize="26px" mt="6">
                Apply Changes to Websites
              </Heading>
              <Divider my="3" />
              <MultiSelectList
                searchable
                allowSelectAll
                leftHeading="Select Websites"
                rightHeading="Apply Changes to Websites"
                data={
                  websites
                    ? websites.map(({ id, domain }) => ({
                        key: id.toString(),
                        value: domain,
                      }))
                    : []
                }
                items={websiteList.items}
                addItem={websiteList.addItem}
                removeItem={websiteList.removeItem}
                clearItems={websiteList.clearItems}
              />
            </Box>
          )}
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button colorScheme="green" isLoading={isLoading} disabled={!isValid} type="button" onClick={onOpen}>
            Submit
          </Button>
          <Button colorScheme="gray" disabled={isLoading} type="button" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
      </form>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount
        closeOnOverlayClick={false}
        size="lg"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Brand Deletion</ModalHeader>
          <ModalCloseButton tabIndex={0} />
          <ModalBody pb={6}>
            <Text>Are you sure you want to delete these brands on all selected websites? </Text>
            <Text color="tomato" fontWeight="bold">
              This cannot be undone!
            </Text>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={3}>
              <Button type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleSubmit(onSubmit)}
                colorScheme="red"
                isLoading={isLoading || isSubmitting}
              >
                Delete Brands
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ContentContainer>
  );
}

export default DeleteBrands;
