import { Heading, Flex, Text } from '@chakra-ui/react';

interface IProps {
  value?: number;
  text: string;
  isLoading: boolean;
}

function Stat({ value, text, isLoading }: IProps) {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading as="h3" size="xl">
        {!isLoading && Number(value).toLocaleString()}
      </Heading>
      <Text mt="1" whiteSpace="nowrap">
        {text}
      </Text>
    </Flex>
  );
}

Stat.defaultProps = {
  value: undefined,
};

export default Stat;
