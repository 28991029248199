import { ButtonGroup, ButtonGroupProps } from '@chakra-ui/react';

type IProps = ButtonGroupProps;

function CustomButtonGroup({ gap = 3, mb = 3, children }: IProps) {
  return (
    <ButtonGroup gap={gap} mb={mb}>
      {children}
    </ButtonGroup>
  );
}

export default CustomButtonGroup;
