import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  SimpleGrid,
  Box,
  Tag,
  TagLabel,
  useColorMode,
} from '@chakra-ui/react';
import { faCircleInfo } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from '../../../helpers/capitalize';
import formatDatetime from '../../../helpers/formatDatetime';
import { IWebsite, PROCESS_STATUS } from '../types';
import PopoverLabel from './PopoverLabel';
import PopoverText from './PopoverText';

interface IProps {
  website: IWebsite;
}

function ProductUpdaterPopover({ website }: IProps) {
  const {
    process_id: processId,
    process_status: status,
    process_stage: stage,
    process_progress: progress,
    process_log: log,
    date_started: dateStarted,
    last_modified: lastModified,
  } = website;

  const { colorMode } = useColorMode();
  const statusText = PROCESS_STATUS[status];
  let statusColor = 'gray';
  let statusTextColor;

  if (status === 1) {
    statusColor = 'orange';
    statusTextColor = colorMode === 'dark' ? 'orange.300' : 'orange.500';
  } else if (status === 2) {
    statusColor = 'green';
    statusTextColor = colorMode === 'dark' ? 'green.300' : 'green.500';
  }

  return (
    <Popover placement="left">
      <PopoverTrigger>
        <Tag colorScheme={statusColor} borderRadius="full" cursor="pointer">
          <FontAwesomeIcon icon={faCircleInfo} size="lg" />
          <TagLabel ml="2">{statusText}</TagLabel>
        </Tag>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Product Updater Details</PopoverHeader>
        <PopoverBody>
          <SimpleGrid columns={2} spacing="3">
            <Box>
              <PopoverLabel>Status:</PopoverLabel>
            </Box>
            <Box>
              <PopoverText color={statusTextColor}>{statusText}</PopoverText>
            </Box>
            <Box>
              <PopoverLabel>Process ID:</PopoverLabel>
            </Box>
            <Box>
              <PopoverText>{processId}</PopoverText>
            </Box>
            <Box>
              <PopoverLabel>Stage:</PopoverLabel>
            </Box>
            <Box>
              <PopoverText>{capitalize(stage)}</PopoverText>
            </Box>
            <Box>
              <PopoverLabel>Progress:</PopoverLabel>
            </Box>
            <Box>
              <PopoverText>{progress}%</PopoverText>
            </Box>
            <Box>
              <PopoverLabel>Log File:</PopoverLabel>
            </Box>
            <Box>
              <PopoverText>{log}</PopoverText>
            </Box>
            <Box>
              <PopoverLabel>Date Started:</PopoverLabel>
            </Box>
            <Box>
              <PopoverText>{formatDatetime(dateStarted)}</PopoverText>
            </Box>
            <Box>
              <PopoverLabel>Last Modified:</PopoverLabel>
            </Box>
            <Box>
              <PopoverText>{formatDatetime(lastModified)}</PopoverText>
            </Box>
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default ProductUpdaterPopover;
