import { useEffect } from 'react';
import {
  Button,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IBrand, IBrandFilter } from './types';
import ContentContainer from '../layout/ContentContainer';
import { brandFilterValidationSchema } from './validationSchema';
import MultiSelectList from '../multiSelectList/MultiSelectList';
import useMap from '../../hooks/useMap';
import { useGetAllBrandsQuery } from './brandApi';

interface IProps {
  heading: string;
  onSubmit: (values: IBrandFilter) => void;
  brandFilter?: IBrandFilter;
}

function BrandFilterForm({ heading, onSubmit, brandFilter }: IProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<IBrandFilter>({
    resolver: yupResolver(brandFilterValidationSchema),
  });

  const brandList = useMap();
  const { data: brands } = useGetAllBrandsQuery();

  useEffect(() => {
    if (brandFilter) {
      if (brandFilter.brands && !brandList.initialized) {
        Object.keys(brandFilter.brands).forEach((key) => {
          brandList.addItem(key, brandFilter.brands[key]);
        });
      }

      brandList.setInitialized(true);
      reset(brandFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandFilter, reset, brandList.initialized]);

  useEffect(() => {
    setValue('brands', Object.fromEntries(brandList.items));
  }, [setValue, brandList]);

  return (
    <ContentContainer heading={heading}>
      <Button
        as={Link}
        to="/brand/filter"
        variant="ghost"
        colorScheme="blue"
        aria-label="All Custom Filters"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        All Custom Filters
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <Heading as="h2" fontSize="26px">
            Custom Filter Details
          </Heading>
          <Divider my="3" />
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input id="name" placeholder="Name" {...register('name')} />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>
          {brands && (
            <Box>
              <Heading as="h2" fontSize="26px">
                Brands
              </Heading>
              <Divider my="3" />
              <MultiSelectList
                searchable
                allowSelectAll
                leftHeading="Select Brands"
                rightHeading="Selected Brands"
                data={brands.map(({ aaia_brand_code, name }: IBrand) => ({
                  key: aaia_brand_code,
                  value: `${name} (${aaia_brand_code})`,
                }))}
                items={brandList.items}
                addItem={brandList.addItem}
                removeItem={brandList.removeItem}
                clearItems={brandList.clearItems}
              />
            </Box>
          )}
        </SimpleGrid>
        <Button mt={6} colorScheme="green" isLoading={isSubmitting} type="button" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </form>
    </ContentContainer>
  );
}

BrandFilterForm.defaultProps = {
  brandFilter: undefined,
};

export default BrandFilterForm;
