import {
  SimpleGrid,
  Flex,
  Box,
  InputGroup,
  Input,
  Button,
  Text,
  Heading,
  useColorMode,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import { faMagnifyingGlass, faTimes, faPlusSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, createRef, useCallback, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import useDebounce from '../../hooks/useDebounce';
import { IDataType } from './types';

interface IList {
  // eslint-disable-next-line react/no-unused-prop-types
  index: number;
  // eslint-disable-next-line react/no-unused-prop-types
  style: React.CSSProperties;
  data: IDataType[];
}

interface IProps {
  leftHeading?: string;
  rightHeading?: string;
  searchable?: boolean;
  allowSelectAll?: boolean;
  data: IDataType[];
  listHeight?: number;
  draw?: number;
  items: Map<string, string>;
  addItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
  clearItems: () => void;
  searchChangeHandler?: (value: string) => void;
}

function MultiSelectList({
  leftHeading,
  rightHeading,
  searchable,
  allowSelectAll,
  data,
  listHeight = 300,
  draw = 1,
  items,
  addItem,
  removeItem,
  clearItems,
  searchChangeHandler,
}: IProps) {
  const [searchResults, setSearchResults] = useState<IDataType[]>(data);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm: string = useDebounce<string>(searchValue, 500);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);
  const clearSearch = () => setSearchValue('');
  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.200';
  const bg = colorMode === 'dark' ? 'whiteAlpha.50' : 'gray.100';
  const searchInputHeight = 41;
  const listAddonHeight = 40;
  const listWidth = useBreakpointValue(
    {
      base: '100%',
      '2xl': '554px',
    },
    {
      fallback: '2xl',
      ssr: false,
    },
  );
  const itemSize = 45;
  const listRef = useRef<FixedSizeList>(null);

  const scrollToTop = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(0);
    }
  }, [listRef]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (searchChangeHandler) {
        searchChangeHandler(debouncedSearchTerm);
        setSearchResults(data);
      } else {
        const filtered = data.filter(({ value }) => value.toLowerCase().includes(debouncedSearchTerm.toLowerCase()));
        setSearchResults(filtered);
      }

      scrollToTop();
    } else {
      if (searchChangeHandler) {
        setSearchResults([]);
      } else {
        setSearchResults(data);
      }

      scrollToTop();
    }
  }, [data, debouncedSearchTerm, scrollToTop, searchChangeHandler]);

  const addAll = () => {
    data.forEach(({ key, value }) => {
      if (!items.has(key)) {
        addItem(key, value);
      }
    });
  };

  useEffect(() => {
    if (draw !== 1) {
      clearSearch();
      clearItems();
    }
  }, [draw, clearItems]);

  const selectedResults = Array.from(items[Symbol.iterator]()).sort((a, b) => {
    if (a[1] === b[1]) {
      return 0;
    }

    return a[1] < b[1] ? -1 : 1;
  });

  const searchListHeight = () => {
    if (searchValue && !searchResults.length) {
      return listHeight - listAddonHeight;
    }

    return listHeight;
  };

  const selectedListHeight = () => {
    if (items.size) {
      return searchable ? listHeight + searchInputHeight : listHeight;
    }

    return searchable ? listHeight + (searchInputHeight - listAddonHeight) : listHeight - listAddonHeight;
  };

  return (
    <SimpleGrid minChildWidth={listWidth} spacing="6">
      <Box>
        <Heading size="sm" mb="3" textAlign="center">
          {`${leftHeading} (${searchResults.length})`}
        </Heading>
        <Flex flexDirection="column" border="1px" rounded="md" bg={bg} borderColor={borderColor} p="3">
          {searchable && (
            <InputGroup mb="1px">
              <InputLeftElement pointerEvents="none">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputLeftElement>
              <Input placeholder="Search" variant="flushed" onChange={handleSearchChange} value={searchValue} />
              {searchValue && (
                <InputRightElement>
                  <IconButton
                    aria-label="Clear Search"
                    icon={<FontAwesomeIcon icon={faTimes} />}
                    variant="link"
                    onClick={clearSearch}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          )}
          <Box>
            {searchValue && !searchResults.length && (
              <Text textAlign="center" color="gray.500" p="2">
                No matches found
              </Text>
            )}
            <FixedSizeList
              ref={listRef}
              width={listWidth || '100%'}
              height={searchListHeight()}
              itemCount={data.length}
              itemSize={itemSize}
              itemData={searchResults}
            >
              {({ index, style, data: listData }: IList) =>
                listData[index] && (
                  <Flex alignItems="center" gap="3" style={style}>
                    <IconButton
                      aria-label={`Add ${listData[index].value}`}
                      variant="ghost"
                      colorScheme="green"
                      icon={<FontAwesomeIcon icon={faPlusSquare} />}
                      onClick={() => {
                        addItem(listData[index].key, listData[index].value);
                      }}
                      ml="1"
                    />
                    <Text>{listData[index].value}</Text>
                  </Flex>
                )
              }
            </FixedSizeList>
          </Box>
          {allowSelectAll && (
            <Box textAlign="center">
              <Button variant="ghost" colorScheme="blue" onClick={addAll}>
                Select All
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
      <Box>
        <Heading size="sm" mb="3" textAlign="center">
          {`${rightHeading} (${items.size})`}
        </Heading>
        <Flex flexDirection="column" border="1px" rounded="md" bg={bg} borderColor={borderColor} p="3">
          <Box>
            {!items.size && (
              <Text textAlign="center" color="gray.500" p="2">
                Nothing selected
              </Text>
            )}
            <FixedSizeList
              width={listWidth || '100%'}
              height={selectedListHeight()}
              itemCount={selectedResults.length}
              itemSize={45}
              itemData={selectedResults.map(([key, value]) => ({ key, value }))}
            >
              {({ index, style, data: listData }: IList) => (
                <Flex alignItems="center" gap="3" style={style}>
                  <Text>{listData[index].value}</Text>
                  <Spacer />
                  <IconButton
                    aria-label={`Remove ${listData[index].value}`}
                    variant="ghost"
                    colorScheme="red"
                    icon={<FontAwesomeIcon icon={faTimes} />}
                    onClick={() => {
                      removeItem(listData[index].key);
                    }}
                  />
                </Flex>
              )}
            </FixedSizeList>
          </Box>
          {allowSelectAll && (
            <Box textAlign="center">
              <Button variant="ghost" colorScheme="blue" onClick={clearItems} disabled={!items.size}>
                Deselect All
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
    </SimpleGrid>
  );
}

MultiSelectList.defaultProps = {
  leftHeading: 'Select Options',
  rightHeading: 'Selected Options',
  searchable: false,
  allowSelectAll: true,
  listHeight: 300,
  draw: 1,
  searchChangeHandler: undefined,
};

export default MultiSelectList;
