export const isTestEnv = () => false;
// return process.env.VITEST !== undefined;

export const isDevEnv = () => import.meta.env.DEV;

export const isProdEnv = () => import.meta.env.PROD;

export const getApiUrl = () => (isTestEnv() ? 'http://localhost/api/' : import.meta.env.VITE_API_URL);

export const getServerUrl = () => (isTestEnv() ? 'http://localhost/' : import.meta.env.VITE_SERVER_URL);
