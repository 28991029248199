import { SimpleGrid, ButtonGroup, Button, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import ContentContainer from '../../layout/ContentContainer';
import AllScriptsButton from '../AllScriptsButton';
import { usePaymentGatewayUsageMutation } from '../scriptApi';
import ScriptResults from '../ScriptResults';
import MultiSelectList from '../../multiSelectList/MultiSelectList';
import AccordionGroupResults from '../AccordionGroupResults';
import { IUsageGroupRequest } from '../types';
import usageGroupValidation from '../validationSchema';
import useMap from '../../../hooks/useMap';

const listItems = [
  ['affirm', 'Affirm'],
  ['authorizenet', 'Authorize.Net'],
  ['braintree', 'Braintree'],
  ['cod', 'Pay In Store'],
  ['datacap', 'Datacap'],
  ['elavon_bnpl', 'Elavon BNPL'],
  ['firstpay', '1stPay'],
  ['free_checkout', 'Free Checkout'],
  ['paybright', 'PayBright'],
  ['payjunction', 'PayJunction (Aftermarket Payments)'],
  ['pp_express', 'PayPal Express'],
  ['square', 'Square'],
  ['stripe', 'Stripe'],
];

function PaymentGatewayUsage() {
  const toast = useToast();
  const [paymentGatewayUsage, { data, isUninitialized, isLoading, isSuccess, isError, reset: resetData }] =
    usePaymentGatewayUsageMutation();
  const { items, addItem, removeItem, clearItems } = useMap();

  const {
    handleSubmit,
    reset: resetForm,
    setValue,
  } = useForm<IUsageGroupRequest>({
    resolver: yupResolver(usageGroupValidation),
  });

  useEffect(() => {
    setValue(
      'items',
      Array.from(items[Symbol.iterator]()).map(([key, value]) => ({ code: key, name: value })),
    );
  }, [items, setValue]);

  const onSubmit = async (values: IUsageGroupRequest) => {
    try {
      await paymentGatewayUsage(values);
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const onReset = () => {
    resetForm();
    resetData();
    clearItems();
  };

  return (
    <ContentContainer heading="Payment Gateway Usage">
      <AllScriptsButton />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <MultiSelectList
            searchable
            leftHeading="Select Payment Gateways"
            rightHeading="Selected Payment Gateways"
            data={listItems
              .sort((a, b) => {
                if (a[1] === b[1]) {
                  return 0;
                }

                return a[1] < b[1] ? -1 : 1;
              })
              .map(([key, value]) => ({ key, value }))}
            items={items}
            addItem={addItem}
            removeItem={removeItem}
            clearItems={clearItems}
          />
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button colorScheme="green" isLoading={isLoading} type="submit">
            Submit
          </Button>
          <Button colorScheme="gray" disabled={isLoading} type="button" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <ScriptResults isUninitialized={isUninitialized} isLoading={isLoading} isSuccess={isSuccess} isError={isError}>
          {isSuccess && data && <AccordionGroupResults results={data} />}
        </ScriptResults>
      </form>
    </ContentContainer>
  );
}

export default PaymentGatewayUsage;
