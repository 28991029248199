import { Center, useColorMode } from '@chakra-ui/react';
import SyncLoader from 'react-spinners/SyncLoader';
import { IThemedLoader } from './types';

function ThemedSyncLoader({ center, size = 12, margin, loading, cssOverride, speedMultiplier }: IThemedLoader) {
  const { colorMode } = useColorMode();
  const color = colorMode === 'dark' ? '#ddd' : '#1a365d';

  const loader = (
    <SyncLoader
      color={color}
      size={size}
      margin={margin}
      loading={loading}
      cssOverride={cssOverride}
      speedMultiplier={speedMultiplier}
      data-testid="sync-loader"
    />
  );

  return center ? <Center>{loader}</Center> : loader;
}

export default ThemedSyncLoader;
