import { Button } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function AllScriptsButton() {
  return (
    <Button
      as={Link}
      to="/script"
      variant="ghost"
      colorScheme="blue"
      aria-label="All Scripts"
      leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
      mb={6}
    >
      All Scripts
    </Button>
  );
}

export default AllScriptsButton;
