import { isToday } from 'date-fns';
import format from 'date-fns/format';

export default (datetime: string | null) => {
  if (typeof datetime !== 'string') {
    return 'N/A';
  }

  const d = new Date(datetime);

  if (isToday(d)) {
    return format(d, 'h:mm aaa');
  }

  return format(new Date(datetime), 'M/d/y h:mm aaa');
};
