import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  useDisclosure,
  ButtonGroup,
  Select,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useGetBrandFiltersQuery } from './brandApi';
import { IKeyValue } from './types';

interface IProps {
  confirmAction: (brands: IKeyValue) => void;
}

function BrandFilterModal(props: IProps) {
  const { data: brandFilters } = useGetBrandFiltersQuery();
  const { confirmAction } = props;
  const [selectedFilter, setSelectedFilter] = useState<number | undefined>(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const resetAndClose = () => {
    setSelectedFilter(undefined);
    onClose();
  };

  const onSubmit = () => {
    const brands = brandFilters?.find((brandFilter) => brandFilter.id === selectedFilter)?.brands;

    if (brands) {
      confirmAction(brands);
      resetAndClose();
    }
  };

  return (
    <>
      <Button colorScheme="teal" size="sm" onClick={onOpen} ref={finalRef}>
        Custom Filters
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount
        closeOnOverlayClick={false}
        size="lg"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a Custom Filter</ModalHeader>
          <ModalCloseButton tabIndex={0} />
          <ModalBody pb={6}>
            <FormControl>
              <Select
                placeholder="Select option"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(parseInt(e.target.value, 10))}
              >
                {brandFilters &&
                  brandFilters.map((brandFilter) => (
                    <option key={brandFilter.id} value={brandFilter.id}>
                      {brandFilter.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={3}>
              <Button onClick={resetAndClose}>Cancel</Button>
              <Button type="button" colorScheme="green" onClick={onSubmit}>
                Confirm
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BrandFilterModal;
