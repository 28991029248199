import { SimpleGrid, ButtonGroup, Button, useToast } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ContentContainer from '../layout/ContentContainer';
import { useGetGlobalBrandsQuery, useUpdateGlobalBrandsMutation } from './brandApi';
import { IBrand, IUpdateDisabledBrands } from './types';
import { brandListValidationSchema } from './validationSchema';
import MultiSelectList from '../multiSelectList/MultiSelectList';
import useMap from '../../hooks/useMap';

function GlobalBrands() {
  const toast = useToast();
  const { data, isLoading } = useGetGlobalBrandsQuery();
  const [updateGlobalBrands, { isLoading: isSubmitting }] = useUpdateGlobalBrandsMutation();
  const { items, initialized, setInitialized, addItem, removeItem, clearItems } = useMap();

  const { handleSubmit, setValue } = useForm<IUpdateDisabledBrands>({
    resolver: yupResolver(brandListValidationSchema),
  });

  useEffect(() => {
    // Set initial items
    if (data && !initialized) {
      data.disabled.forEach(({ aaia_brand_code, name }: IBrand) => {
        addItem(aaia_brand_code, `${name} (${aaia_brand_code})`);
      });

      setInitialized(true);
    }
  }, [data, initialized, setInitialized, addItem]);

  useEffect(() => {
    setValue(
      'disabled',
      Array.from(items[Symbol.iterator]()).map(([key, value]) => ({ code: key, name: value })),
    );
  }, [items, setValue]);

  const onSubmit = async (values: IUpdateDisabledBrands) => {
    try {
      const { error } = await updateGlobalBrands(values).unwrap();

      if (error) {
        toast({
          title: error,
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          title: 'The global disabled brands have been updated!',
          status: 'success',
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to update the global disabled brands!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <ContentContainer heading="Global Disabled Brands">
      <Button
        as={RouterLink}
        to="/brand"
        variant="ghost"
        colorScheme="blue"
        aria-label="All Websites"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        Back to Brands
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 2, lg: 1 }} spacing="3">
          {data && (
            <MultiSelectList
              searchable
              allowSelectAll
              leftHeading="Select Brands"
              rightHeading="Global Disabled Brands"
              data={data.brands.map(({ aaia_brand_code, name }: IBrand) => ({
                key: aaia_brand_code,
                value: `${name} (${aaia_brand_code})`,
              }))}
              items={items}
              addItem={addItem}
              removeItem={removeItem}
              clearItems={clearItems}
            />
          )}
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button colorScheme="green" isLoading={isLoading || isSubmitting} type="submit">
            Submit
          </Button>
        </ButtonGroup>
      </form>
    </ContentContainer>
  );
}

export default GlobalBrands;
