import { IconButton, Tooltip, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSunBright, faMoonStars } from '@fortawesome/pro-duotone-svg-icons';

function ColorModeToggle() {
  const { colorMode, toggleColorMode } = useColorMode();
  const icon = colorMode === 'dark' ? faSunBright : faMoonStars;
  const color = colorMode === 'dark' ? 'yellow.400' : 'blue.600';
  const label = colorMode === 'dark' ? 'Light Mode' : 'Dark Mode';

  return (
    <Tooltip label={label} hasArrow>
      <IconButton color={color} aria-label={label} icon={<FontAwesomeIcon icon={icon} />} onClick={toggleColorMode} />
    </Tooltip>
  );
}

export default ColorModeToggle;
