import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { ISettingUsage } from './settingUsage/types';
import {
  IUsageGroupRequest,
  IUsageGroupResponse,
  IWebsite,
  IScriptResponse,
  IUserLoginResponse,
  IDeleteBrandsRequest,
  IDeleteBrandsResponse,
  IBrandFile,
  IDeleteHiddenBrandFileResponse,
  IGenerateBrandFileRequest,
} from './types';

export const scriptApi = createApi({
  reducerPath: 'scriptApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['UserLogins'],
  endpoints: (builder) => ({
    settingUsage: builder.mutation<IWebsite[], ISettingUsage>({
      query: (data) => ({
        url: 'script/setting-usage',
        method: 'POST',
        data,
      }),
    }),
    brandUsage: builder.mutation<IUsageGroupResponse, IUsageGroupRequest>({
      query: (data) => ({
        url: 'script/brand-usage',
        method: 'POST',
        data,
      }),
    }),
    deleteBrands: builder.mutation<IDeleteBrandsResponse, IDeleteBrandsRequest>({
      query: (data) => ({
        url: 'script/delete-brands',
        method: 'POST',
        data,
      }),
    }),
    paymentGatewayUsage: builder.mutation<IUsageGroupResponse, IUsageGroupRequest>({
      query: (data) => ({
        url: 'script/payment-gateway-usage',
        method: 'POST',
        data,
      }),
    }),
    shippingGatewayUsage: builder.mutation<IUsageGroupResponse, IUsageGroupRequest>({
      query: (data) => ({
        url: 'script/shipping-gateway-usage',
        method: 'POST',
        data,
      }),
    }),
    integrationUsage: builder.mutation<IUsageGroupResponse, IUsageGroupRequest>({
      query: (data) => ({
        url: 'script/integration-usage',
        method: 'POST',
        data,
      }),
    }),
    customBrandPageUsage: builder.mutation<IUsageGroupResponse, IUsageGroupRequest>({
      query: (data) => ({
        url: 'script/custom-brand-page-usage',
        method: 'POST',
        data,
      }),
    }),
    generateBrandFile: builder.mutation<IScriptResponse, IGenerateBrandFileRequest>({
      query: (data) => ({
        url: 'script/generate-brand-file',
        method: 'POST',
        data,
      }),
    }),
    getHiddenBrandFiles: builder.query<IBrandFile[], void>({
      query: () => ({
        url: 'script/generate-brand-file/hidden',
        method: 'GET',
      }),
    }),
    deleteHiddenBrandFile: builder.mutation<IDeleteHiddenBrandFileResponse, string>({
      query: (file) => ({
        url: `script/generate-brand-file/delete`,
        method: 'DELETE',
        data: { file },
      }),
    }),
    createDemoSnapshot: builder.mutation<IScriptResponse, void>({
      query: (data) => ({
        url: 'script/create-demo-snapshot',
        method: 'POST',
        data,
      }),
    }),
    userLogins: builder.query<IUserLoginResponse, void>({
      query: () => ({
        url: 'script/user-logins',
        method: 'GET',
        provideTags: ['UserLogins'],
      }),
    }),
  }),
});

export const {
  useSettingUsageMutation,
  useBrandUsageMutation,
  useDeleteBrandsMutation,
  usePaymentGatewayUsageMutation,
  useShippingGatewayUsageMutation,
  useIntegrationUsageMutation,
  useCustomBrandPageUsageMutation,
  useGenerateBrandFileMutation,
  useGetHiddenBrandFilesQuery,
  useDeleteHiddenBrandFileMutation,
  useCreateDemoSnapshotMutation,
  useUserLoginsQuery,
} = scriptApi;
