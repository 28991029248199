import { useEffect, useState } from 'react';
import {
  Button,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  Box,
  Heading,
  Divider,
  Switch,
  Tooltip,
  Text,
  useColorMode,
  Textarea,
  FormHelperText,
} from '@chakra-ui/react';
import { faArrowLeft, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ICategory, IFormValues, IGlobalInstantRebate, IPart, ISeries, ISeriesRequest } from './types';
import ContentContainer from '../layout/ContentContainer';
import validationSchema from './validationSchema';
import {
  useGetAllBrandsQuery,
  useGetAllCategoriesQuery,
  useGetAllSeriesQuery,
  useGetPartsQuery,
} from './globalInstantRebateApi';
import MultiSelectList from '../multiSelectList/MultiSelectList';
import useMap from '../../hooks/useMap';
import { IBrand } from '../brand/types';

interface IProps {
  heading: string;
  onSubmit: (values: IFormValues) => void;
  globalInstantRebate?: IGlobalInstantRebate;
}

function GlobalInstantRebateForm({ heading, onSubmit, globalInstantRebate }: IProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<IFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const { colorMode } = useColorMode();
  const [useFreeformParts, setUseFreeformParts] = useState(false);
  const [freeformParts, setFreeformParts] = useState('');
  const [partSearch, setPartSearch] = useState<string>('');
  const categoryList = useMap();
  const brandList = useMap();
  const seriesList = useMap();
  const partList = useMap();
  const selectedBrands: ISeriesRequest | undefined =
    brandList.items.size > 0 ? { brands: Array.from(brandList.items.keys()) } : undefined;
  const { data: categories } = useGetAllCategoriesQuery();
  const { data: brands } = useGetAllBrandsQuery();
  const { data: series } = useGetAllSeriesQuery(selectedBrands ?? skipToken);
  const { data: parts } = useGetPartsQuery(
    selectedBrands && partSearch ? { ...selectedBrands, part: partSearch } : skipToken,
  );

  useEffect(() => {
    if (globalInstantRebate) {
      if (globalInstantRebate.brands && !brandList.initialized) {
        Object.keys(globalInstantRebate.brands).forEach((key) => {
          brandList.addItem(key, globalInstantRebate.brands[key]);
        });
      }

      if (globalInstantRebate.categories && !categoryList.initialized) {
        Object.keys(globalInstantRebate.categories).forEach((key) => {
          categoryList.addItem(key, globalInstantRebate.categories[key]);
        });
      }

      if (globalInstantRebate.series && !seriesList.initialized) {
        Object.keys(globalInstantRebate.series).forEach((key) => {
          seriesList.addItem(key, globalInstantRebate.series[key]);
        });
      }

      if (globalInstantRebate.parts && !partList.initialized) {
        Object.keys(globalInstantRebate.parts).forEach((key) => {
          partList.addItem(key, globalInstantRebate.parts[key]);
          setFreeformParts((prev) => `${prev}${key.split('~')[1]}\n`);
        });
      }

      brandList.setInitialized(true);
      categoryList.setInitialized(true);
      seriesList.setInitialized(true);
      partList.setInitialized(true);
      reset(globalInstantRebate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalInstantRebate,
    reset,
    brandList.initialized,
    categoryList.initialized,
    seriesList.initialized,
    partList.initialized,
  ]);

  useEffect(() => {
    setValue('categories', Object.fromEntries(categoryList.items));
  }, [setValue, categoryList]);

  useEffect(() => {
    setValue('brands', Object.fromEntries(brandList.items));
  }, [setValue, brandList]);

  useEffect(() => {
    setValue('series', Object.fromEntries(seriesList.items));
  }, [setValue, seriesList]);

  useEffect(() => {
    setValue('parts', Object.fromEntries(partList.items));
  }, [setValue, partList]);

  useEffect(() => {
    setValue('freeform_parts', freeformParts);
  }, [setValue, freeformParts]);

  return (
    <ContentContainer heading={heading}>
      <Button
        as={Link}
        to="/global-instant-rebate"
        variant="ghost"
        colorScheme="blue"
        aria-label="All Global Instant Rebates"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        All Global Instant Rebates
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <Heading as="h2" fontSize="26px">
            Instant Rebate Details
          </Heading>
          <Divider my="3" />
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input id="name" placeholder="Name" {...register('name')} />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="3">
            <FormControl isRequired isInvalid={!!errors.type}>
              <FormLabel htmlFor="type">Discount Type</FormLabel>
              <Select id="type" placeholder="Discount Type" {...register('type')}>
                <option value="F">Fixed</option>
                <option value="P">Percentage</option>
              </Select>
              <FormErrorMessage>{errors.type && errors.type.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.discount}>
              <FormLabel htmlFor="discount">Discount Amount</FormLabel>
              <Input id="discount" placeholder="Discount Amount" {...register('discount')} />
              <FormErrorMessage>{errors.discount && errors.discount.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="3">
            <FormControl isRequired isInvalid={!!errors.date_start}>
              <FormLabel htmlFor="date_start">Start Date</FormLabel>
              <Input id="date_start" placeholder="Start Date" {...register('date_start')} type="date" />
              <FormErrorMessage>{errors.date_start && errors.date_start.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.date_end}>
              <FormLabel htmlFor="date_end">End Date</FormLabel>
              <Input id="date_end" placeholder="End Date" {...register('date_end')} type="date" />
              <FormErrorMessage>{errors.date_end && errors.date_end.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <FormControl display="flex" alignItems="center">
            <Switch id="link_selections" {...register('link_selections')} />
            <FormLabel htmlFor="link_selections" mb="0" ml="3">
              Link Category, Brand, and Series Selections
            </FormLabel>
            <Tooltip
              label={`
                If enabled, the instant rebate will only be applied to products that 
                match a selected category, brand, AND series. Otherwise, it will be 
                applied if products match any of the selected items.
                `}
              rounded="md"
              placement="right-start"
              hasArrow
            >
              <Text display="inline-block" color={colorMode === 'dark' ? 'blue.200' : 'blue.600'}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Text>
            </Tooltip>
          </FormControl>
          {categories && (
            <Box>
              <Heading as="h2" fontSize="26px">
                Categories
              </Heading>
              <Divider my="3" />
              <MultiSelectList
                searchable
                allowSelectAll
                leftHeading="Select Categories"
                rightHeading="Selected Categories"
                data={categories.map(({ name }: ICategory) => ({
                  key: name,
                  value: name,
                }))}
                items={categoryList.items}
                addItem={categoryList.addItem}
                removeItem={categoryList.removeItem}
                clearItems={categoryList.clearItems}
              />
            </Box>
          )}
          {brands && (
            <Box>
              <Heading as="h2" fontSize="26px">
                Brands
              </Heading>
              <Divider my="3" />
              <MultiSelectList
                searchable
                allowSelectAll
                leftHeading="Select Brands"
                rightHeading="Selected Brands"
                data={brands.map(({ aaia_brand_code, name }: IBrand) => ({
                  key: aaia_brand_code,
                  value: `${name} (${aaia_brand_code})`,
                }))}
                items={brandList.items}
                addItem={brandList.addItem}
                removeItem={brandList.removeItem}
                clearItems={brandList.clearItems}
              />
            </Box>
          )}
          <Box>
            <Heading as="h2" fontSize="26px">
              Series (requires brand selection)
            </Heading>
            <Divider my="3" />
            <MultiSelectList
              searchable
              allowSelectAll
              leftHeading="Select Series"
              rightHeading="Selected Series"
              data={
                series
                  ? series.map(({ aaia, brand, series: seriesName }: ISeries) => ({
                      key: `${aaia}~${seriesName}`,
                      value: `${brand} > ${seriesName}`,
                    }))
                  : []
              }
              items={seriesList.items}
              addItem={seriesList.addItem}
              removeItem={seriesList.removeItem}
              clearItems={seriesList.clearItems}
            />
          </Box>
          <Box>
            <Heading as="h2" fontSize="26px">
              Parts (requires brand selection)
            </Heading>
            <Divider my="3" />
            <FormControl display="flex" alignItems="center" my="3">
              <Switch
                id="use_freeform_parts"
                {...register('use_freeform_parts')}
                onChange={(e) => setUseFreeformParts(e.target.checked)}
              />
              <FormLabel htmlFor="use_freeform_parts" mb="0" ml="3">
                Use Free-form Part List
              </FormLabel>
            </FormControl>
            {useFreeformParts && (
              <FormControl>
                <FormHelperText my="3">One part number per line.</FormHelperText>
                <Textarea
                  placeholder="Paste or enter part numbers one per line."
                  minH={407}
                  {...register('freeform_parts')}
                />
              </FormControl>
            )}
            {!useFreeformParts && (
              <MultiSelectList
                searchable
                allowSelectAll
                leftHeading="Select Parts"
                rightHeading="Selected Parts"
                data={
                  parts
                    ? parts.map(({ aaia, brand, part }: IPart) => ({
                        key: `${aaia}~${part}`,
                        value: `${brand} ${part}`,
                      }))
                    : []
                }
                items={partList.items}
                addItem={partList.addItem}
                removeItem={partList.removeItem}
                clearItems={partList.clearItems}
                searchChangeHandler={(search: string) => {
                  setPartSearch(search);
                }}
              />
            )}
          </Box>
        </SimpleGrid>
        <Button mt={6} colorScheme="green" isLoading={isSubmitting} type="button" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </form>
    </ContentContainer>
  );
}

GlobalInstantRebateForm.defaultProps = {
  globalInstantRebate: undefined,
};

export default GlobalInstantRebateForm;
