import { Text, Box, Center, Spinner, useColorMode, Heading, BoxProps } from '@chakra-ui/react';

interface IProps extends BoxProps {
  isUninitialized?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  children: React.ReactNode;
}

function ScriptResults({ isUninitialized, isLoading, isSuccess, isError, children, overflowY, h }: IProps) {
  const { colorMode } = useColorMode();
  const bg = colorMode === 'dark' ? 'whiteAlpha.50' : 'gray.100';

  if (isUninitialized) {
    return null;
  }

  return (
    <Box mt="6">
      <Heading size="sm" mb="3">
        Result
      </Heading>
      <Box p="3" borderRadius="md" bg={bg} boxShadow="inner" h={h} overflowY={overflowY}>
        {isLoading && (
          <Center>
            <Spinner size="lg" color={colorMode === 'dark' ? 'purple.300' : 'purple.700'} />
          </Center>
        )}
        {isSuccess && children}
        {isError && (
          <Center>
            <Text colorScheme="red">An error occurred.</Text>
          </Center>
        )}
      </Box>
    </Box>
  );
}

ScriptResults.defaultProps = {
  isUninitialized: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export default ScriptResults;
