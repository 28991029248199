import { Center, useColorMode } from '@chakra-ui/react';
import PuffLoader from 'react-spinners/PuffLoader';
import { IThemedLoader } from './types';

function ThemedPuffLoader({ center, size, loading, cssOverride, speedMultiplier }: IThemedLoader) {
  const { colorMode } = useColorMode();
  const color = colorMode === 'dark' ? '#ffffff' : '#1a365d';

  const loader = (
    <PuffLoader
      color={color}
      size={size}
      loading={loading}
      cssOverride={cssOverride}
      speedMultiplier={speedMultiplier}
    />
  );

  return center ? <Center>{loader}</Center> : loader;
}

export default ThemedPuffLoader;
