import * as yup from 'yup';

export default yup.object().shape({
  dateRange: yup.string(),
  startDate: yup.string().when('dateRange', {
    is: 'customRange',
    then: (schema) => schema.required('You must select a date range.'),
  }),
  endDate: yup.string().when('dateRange', {
    is: 'customRange',
    then: (schema) => schema.required('You must select a date range.'),
  }),
});
