import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getServerUrl } from '../../envUtils';
import { ISystemUser } from '../systemUser/types';

export interface ILoginResponse {
  isAuthenticated: boolean;
  authenticatedUser: ISystemUser;
  jwt: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getServerUrl() }) : axiosBaseQuery(getServerUrl()),
  endpoints: (builder) => ({
    getCsrfCookie: builder.query<void, void>({
      query: () => ({
        url: 'sanctum/csrf-cookie',
        method: 'get',
      }),
    }),
    login: builder.query<ILoginResponse, void>({
      query: () => ({
        url: 'api/login',
        method: 'post',
      }),
    }),
  }),
});

export const { useGetCsrfCookieQuery, useLoginQuery } = authApi;
