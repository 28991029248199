import randomCharFrom from './randomCharFrom';
import shuffle from './shuffle';

export default (length = 32): string => {
  const digits = '0123456789'; // at least 3
  const lower = 'abcdefghijklmnopqrstuvwxyz'; // at least 1
  const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // at least 1
  const special = '#$^*?_~'; // at least 2
  const combined = digits.concat(lower, upper, special);
  const characters: string[] = [];

  for (let i = 0; i < 3; i += 1) {
    characters.push(randomCharFrom(digits));
  }

  characters.push(randomCharFrom(lower));
  characters.push(randomCharFrom(upper));

  for (let i = 0; i < 2; i += 1) {
    characters.push(randomCharFrom(special));
  }

  const numRemaining = length - characters.length;

  for (let i = 0; i < numRemaining; i += 1) {
    characters.push(randomCharFrom(combined));
  }

  shuffle(characters);

  return characters.join('');
};
