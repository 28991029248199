import { Center, Flex, Text, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import PageHeading from '../heading/PageHeading';

function NotFound() {
  return (
    <Center p="6" textAlign="center">
      <Flex direction="column">
        <PageHeading>Oops!</PageHeading>
        <Text fontSize="lg">
          There&apos;s nothing here.{' '}
          <Link as={RouterLink} to="/" color="blue" textDecoration="underline">
            Go back home.
          </Link>
        </Text>
      </Flex>
    </Center>
  );
}

export default NotFound;
