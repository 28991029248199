import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useColorMode,
} from '@chakra-ui/react';
import WebsiteResults from './WebsiteResults';
import { IUsageGroupResponse } from './types';

interface IProps {
  results: IUsageGroupResponse;
}

function AccordionGroupResults({ results }: IProps) {
  const { colorMode } = useColorMode();

  const sortedResults = Object.keys(results).sort((aKey, bKey) => {
    const { name: aName, websites: aWebsites } = results[aKey];
    const { name: bName, websites: bWebsites } = results[bKey];
    let byName = 0;

    if (aName !== bName) {
      byName = aName < bName ? -1 : 1;
    }

    return bWebsites.length - aWebsites.length || byName;
  });

  return (
    <Accordion allowMultiple allowToggle>
      {sortedResults.map((key) => {
        const { name, websites } = results[key];

        if (!websites.length) {
          return (
            <Box key={key} my="3" py="1" px="4" textColor={colorMode === 'dark' ? 'gray.500' : 'gray.400'} rounded="md">
              <h2>No results for {name}</h2>
            </Box>
          );
        }

        return (
          <AccordionItem key={key} border="0">
            <h2>
              <AccordionButton _expanded={{ bg: colorMode === 'dark' ? 'blue.800' : 'blue.100' }} rounded="md">
                <Box flex="1" textAlign="left">
                  {name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <WebsiteResults results={websites} />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

export default AccordionGroupResults;
