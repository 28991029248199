import { Button } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ContentContainer from '../layout/ContentContainer';
import { useGetWebsiteBrandsQuery } from './brandApi';
import BrandsForm from './BrandsForm';

function WebsiteBrands() {
  const params = useParams();
  const websiteId = params.websiteId ? Number(params.websiteId) : undefined;
  const { data } = useGetWebsiteBrandsQuery(websiteId ?? skipToken);

  return (
    <ContentContainer heading={data ? `Edit Brands for ${data.website.domain}` : 'Edit Brands'}>
      <Button
        as={RouterLink}
        to="/brand"
        variant="ghost"
        colorScheme="blue"
        aria-label="Back"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        Back
      </Button>
      <BrandsForm />
    </ContentContainer>
  );
}

export default WebsiteBrands;
