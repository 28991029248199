import { Box, BoxProps, Heading, useColorMode } from '@chakra-ui/react';

interface IProps extends BoxProps {
  heading?: string;
}

function Card({ heading, children }: IProps) {
  const { colorMode } = useColorMode();
  const bgGradient = colorMode === 'dark' ? 'linear(to-t, #1f293c, #283348)' : 'linear(to-t, #f5f5f5, #f5f5f5)';
  const textColor = colorMode === 'dark' ? 'blue.50' : '#2a4365';

  return (
    <Box borderRadius="md" p="6" boxShadow="xs" bgGradient={bgGradient} textColor={textColor} overflow="hidden">
      {heading && (
        <Heading as="h2" size="md" mb="6">
          {heading}
        </Heading>
      )}
      {children}
    </Box>
  );
}

Card.defaultProps = {
  heading: '',
};

export default Card;
