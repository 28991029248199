import { Box, Button, ButtonProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface IProps extends ButtonProps {
  to: string;
}

function SidebarMenuLink({ to, leftIcon, children, bg }: IProps) {
  return (
    <Button
      to={to}
      as={Link}
      h="54"
      w="full"
      bg={bg || 'blackAlpha.50'}
      borderRadius="2xl"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box
        p="1.5"
        mx="3"
        w="8"
        h="8"
        borderRadius="xl"
        bg="blue.800"
        color="blue.100"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {leftIcon}
      </Box>
      <Box>{children}</Box>
    </Button>
  );
}

export default SidebarMenuLink;
