export interface IAdditionalLocation {
  location_id: number;
  location_name: string | null;
  country: string;
  state: string;
  city: string;
  zip: string;
  street: string;
  telephone: string;
  geocode: string;
  lat: number;
  lng: number;
  image: string | null;
  hours: string | null;
  comment: string;
  owner_name: string | null;
  display_location: 1 | 0;
  url: string | null;
  email_address: string | null;
  display_email: 1 | 0;
  website_id: number;
  unique_key: string;
}

export interface IPrimaryLocation {
  is_primary: boolean;
  location_name: string;
  telephone: string;
  geocode: string;
  address: string;
  lat: number;
  lng: number;
  website_id: number;
  unique_key: string;
}

export interface ILocations {
  primary: IPrimaryLocation[];
  additional: IAdditionalLocation[];
}

type PrimaryOrAdditional = IPrimaryLocation | IAdditionalLocation;

export const isPrimaryLocation = (location: PrimaryOrAdditional): location is IPrimaryLocation =>
  (location as IPrimaryLocation).is_primary;
