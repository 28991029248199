import {
  SimpleGrid,
  ButtonGroup,
  Button,
  Switch,
  useToast,
  Flex,
  FormControl,
  FormLabel,
  Spacer,
  Box,
  useColorMode,
  Heading,
  Text,
  Center,
  Tooltip,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import ContentContainer from '../../layout/ContentContainer';
import AllScriptsButton from '../AllScriptsButton';
import {
  useDeleteHiddenBrandFileMutation,
  useGenerateBrandFileMutation,
  useGetHiddenBrandFilesQuery,
} from '../scriptApi';
import ScriptResults from '../ScriptResults';
import MultiSelectList from '../../multiSelectList/MultiSelectList';
import { useGetAllBrandsQuery } from '../../brand/brandApi';
import { IUsageGroupRequest } from '../types';
import usageGroupValidation from '../validationSchema';
import useMap from '../../../hooks/useMap';
import BrandFile from './BrandFile';
import ThemedSyncLoader from '../../loader/ThemedSyncLoader';

function GenerateBrandFile() {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const bg = colorMode === 'dark' ? 'whiteAlpha.50' : 'gray.100';
  const { data: brands } = useGetAllBrandsQuery();
  const [generateBrandFile, { data, isUninitialized, isLoading, isSuccess, isError, reset: resetData }] =
    useGenerateBrandFileMutation();
  const { items, addItem, removeItem, clearItems } = useMap();
  const [showHiddenFiles, setShowHiddenFiles] = useState(false);
  const {
    data: hiddenBrandFiles,
    refetch: refreshHiddenFiles,
    isLoading: isLoadingHiddenFiles,
    isFetching: isFetchingHiddenFiles,
  } = useGetHiddenBrandFilesQuery(undefined, {
    skip: !showHiddenFiles,
  });
  const [deleteHiddenBrandFile] = useDeleteHiddenBrandFileMutation();

  const {
    handleSubmit,
    reset: resetForm,
    setValue,
  } = useForm<IUsageGroupRequest>({
    resolver: yupResolver(usageGroupValidation),
  });

  useEffect(() => {
    setValue(
      'items',
      Array.from(items[Symbol.iterator]()).map(([key, value]) => ({ code: key, name: value })),
    );
  }, [items, setValue]);

  const onSubmit = async (values: IUsageGroupRequest) => {
    try {
      await generateBrandFile(values);

      if (showHiddenFiles) {
        refreshHiddenFiles();
      }
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const onSubmitHiddenFile = async (values: IUsageGroupRequest) => {
    try {
      await generateBrandFile({ ...values, hidden: true });

      if (showHiddenFiles) {
        refreshHiddenFiles();
      }
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const onReset = () => {
    resetForm();
    resetData();
    clearItems();
  };

  const onDelete = async (file: string) => {
    try {
      const { success } = await deleteHiddenBrandFile(file).unwrap();

      if (success) {
        toast({
          title: `Successfully deleted ${file}!`,
          status: 'success',
          isClosable: true,
          position: 'top',
        });

        refreshHiddenFiles();
      } else {
        toast({
          title: `Failed to delete ${file}!`,
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const hiddenBrandFilesContent =
    hiddenBrandFiles && hiddenBrandFiles.length > 0 ? (
      hiddenBrandFiles?.map((file) => <BrandFile key={file.name} onDelete={onDelete} {...file} />)
    ) : (
      <Text textAlign="center">No hidden brand files found.</Text>
    );

  return (
    <ContentContainer heading="Generate Brand File">
      <Flex alignItems="center" gap={2}>
        <AllScriptsButton />
        <Spacer />
        <Box>
          <FormControl display="flex" alignItems="center" mb={6}>
            <FormLabel htmlFor="showHiddenFiles" mb="0">
              Show Hidden Files
            </FormLabel>
            <Switch
              id="showHiddenFiles"
              isChecked={showHiddenFiles}
              onChange={() => setShowHiddenFiles(!showHiddenFiles)}
            />
          </FormControl>
        </Box>
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <MultiSelectList
            searchable
            allowSelectAll={false}
            leftHeading="Select Brands"
            rightHeading="Selected Brands"
            data={
              brands
                ? brands.map(({ aaia_brand_code, name }) => ({
                    key: aaia_brand_code,
                    value: `${name} (${aaia_brand_code})`,
                  }))
                : []
            }
            items={items}
            addItem={addItem}
            removeItem={removeItem}
            clearItems={clearItems}
          />
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button colorScheme="green" isLoading={isLoading} type="submit">
            Generate
          </Button>
          <Button colorScheme="green" isLoading={isLoading} type="button" onClick={handleSubmit(onSubmitHiddenFile)}>
            Generate Hidden File
          </Button>
          <Button colorScheme="gray" disabled={isLoading} type="button" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <ScriptResults
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          h="500"
          overflowY="auto"
        >
          {isSuccess && data !== undefined && <pre>{data.output}</pre>}
        </ScriptResults>
      </form>
      <Box mt="6" display={showHiddenFiles ? 'block' : 'none'}>
        <Flex alignItems="center" mb="3" gap={2}>
          <Flex alignItems="center" gap={2}>
            <Tooltip
              label="Hidden brand files are files that have been generated for unsupported brands that are marked as
                  hidden. These files will NOT show on Aftermarket Websites, but you can click the links below to
                  download the files and provide them to stores."
              rounded="md"
              placement="right-start"
              hasArrow
            >
              <Text display="inline-block" color={colorMode === 'dark' ? 'blue.200' : 'blue.600'}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Text>
            </Tooltip>
            <Heading size="sm">Hidden Brand Files</Heading>
          </Flex>
          <Button
            colorScheme="blue"
            size="xs"
            leftIcon={<FontAwesomeIcon icon={faArrowsRotate} />}
            onClick={refreshHiddenFiles}
          >
            Refresh
          </Button>
        </Flex>
        <Box p="3" borderRadius="md" bg={bg} boxShadow="inner" overflowY="auto">
          {isLoadingHiddenFiles || isFetchingHiddenFiles ? (
            <Center>
              <ThemedSyncLoader />
            </Center>
          ) : (
            hiddenBrandFilesContent
          )}
        </Box>
      </Box>
    </ContentContainer>
  );
}

export default GenerateBrandFile;
