import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { ISystemUser } from './types';

interface IDeleteResponse {
  success: boolean;
  id: number;
}

export const systemUserApi = createApi({
  reducerPath: 'systemUserApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['SystemUsers'],
  endpoints: (builder) => ({
    getSystemUsers: builder.query<ISystemUser[], void>({
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'SystemUsers', id } as const)), { type: 'SystemUsers', id: 'LIST' }]
          : [{ type: 'SystemUsers', id: 'LIST' }],
    }),
    getSystemUser: builder.query<ISystemUser, number>({
      query: (id) => ({
        url: `user/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'SystemUsers', id }],
    }),
    createSystemUser: builder.mutation<ISystemUser, Partial<ISystemUser>>({
      query: (data) => ({
        url: 'user',
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'SystemUsers', id: 'LIST' }],
    }),
    updateSystemUser: builder.mutation<ISystemUser, Partial<ISystemUser>>({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `user/${id}`,
          method: 'PUT',
          data: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'SystemUsers', id }],
    }),
    deleteSystemUser: builder.mutation<IDeleteResponse, number>({
      query: (id) => ({
        url: `user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'SystemUsers', id }],
    }),
  }),
});

export const {
  useGetSystemUsersQuery,
  useGetSystemUserQuery,
  useCreateSystemUserMutation,
  useUpdateSystemUserMutation,
  useDeleteSystemUserMutation,
} = systemUserApi;
