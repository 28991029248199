import { Box, Flex, HStack, Select, Spacer, Spinner, Tag, Text, useColorMode } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { CartesianGrid, XAxis, YAxis, BarChart, ResponsiveContainer, Tooltip, Bar } from 'recharts';
import formatCurrency from '../../helpers/formatCurrency';
import thousands from '../../helpers/thousands';
import DateRangeInput from '../input/DateRangeInput';
import OrderChartTag from './OrderChartTag';
import OrderChartTooltip from './OrderChartTooltip';
import { useGetOrdersQuery } from './statApi';
import { IOrdersForm, IOrdersRequest } from './types';
import validationSchema from './validationSchema';

function Orders() {
  const { colorMode } = useColorMode();
  const barFill = colorMode === 'dark' ? '#2e65cd' : '#a6c5ff';

  const defaultValues: IOrdersForm = {
    dateRange: 'today',
    startDate: null,
    endDate: null,
  };

  const [ordersQueryParams, setOrdersQueryParams] = useState<IOrdersRequest>(defaultValues as IOrdersRequest);

  const { register, watch, setValue } = useForm<IOrdersForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const watchDateRange = watch('dateRange');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const { data, isFetching } = useGetOrdersQuery(ordersQueryParams);

  const onChangeDateRange = (date: [Date | null, Date | null]) => {
    const [start, end] = date;
    setValue('endDate', end);
    setValue('startDate', start);
  };

  useEffect(() => {
    const subscription = watch((values) => {
      const { dateRange, startDate, endDate } = values;

      if (dateRange && (dateRange !== 'customRange' || (startDate && endDate))) {
        setOrdersQueryParams({
          ...values,
          startDate: startDate?.toLocaleDateString('en-US', { timeZone: 'America/New_York' }),
          endDate: endDate?.toLocaleDateString('en-US', { timeZone: 'America/New_York' }),
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box>
      <Flex alignItems="center">
        <HStack spacing={3} mb="3" alignItems="center">
          <Select {...register('dateRange')} placeholder="Select date range" variant="filled" w="auto">
            <option value="today">Today</option>
            <option value="pastWeek">Past Week</option>
            <option value="pastMonth">Past Month</option>
            <option value="past6Months">Past 6 Months</option>
            <option value="past12Months">Past 12 Months</option>
            <option value="customRange">Custom Range</option>
          </Select>
          {watchDateRange === 'customRange' && (
            <DatePicker
              selected={watchStartDate}
              onChange={onChangeDateRange}
              startDate={watchStartDate}
              endDate={watchEndDate}
              customInput={<DateRangeInput />}
              selectsRange
              showPopperArrow={false}
            />
          )}
          {isFetching && (
            <Box>
              <Spinner size="md" />
            </Box>
          )}
        </HStack>
        {data && (
          <>
            <Spacer />
            <OrderChartTag>
              {data.startDate} - {data.endDate}
            </OrderChartTag>
            <OrderChartTag>{thousands(data.count)} orders</OrderChartTag>
            <OrderChartTag>{formatCurrency(data.total)}</OrderChartTag>
          </>
        )}
      </Flex>
      {data && (
        <ResponsiveContainer width="99%" aspect={5}>
          <BarChart data={data.points}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" interval={0} />
            <YAxis dataKey="total" tickFormatter={(value) => formatCurrency(value)} width={120} />
            <Tooltip content={<OrderChartTooltip />} cursor={false} />
            <Bar dataKey="total" fill={barFill} label="$" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}

export default Orders;
