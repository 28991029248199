/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  Flex,
  chakra,
  ButtonGroup,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spacer,
} from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  RowData,
  flexRender,
  SortingState,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import { useState } from 'react';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DebouncedInput from '../input/DebouncedInput';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    isNumeric?: boolean;
    align?: 'left' | 'center' | 'right';
  }
}

interface IProps<T> {
  columns: ColumnDef<T>[];
  data: Array<T>;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

function DataTable<T>({ columns, data }: IProps<T>) {
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Box>
      <Flex w="full" alignItems="center" gap="3" mb="3">
        <Box>
          <Text>Search:</Text>
        </Box>
        <Box>
          <DebouncedInput
            value={globalFilter ?? ''}
            handleChange={(value) => setGlobalFilter(String(value))}
            placeholder="Search"
          />
        </Box>
      </Flex>
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id} colSpan={header.colSpan} isNumeric={header.column.columnDef.meta?.isNumeric}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <chakra.span pl="3">
                        {{
                          asc: <FontAwesomeIcon icon={faCaretUp} />,
                          desc: <FontAwesomeIcon icon={faCaretDown} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </chakra.span>
                    </div>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  isNumeric={cell.column.columnDef.meta?.isNumeric}
                  textAlign={cell.column.columnDef.meta?.align || 'left'}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex w="full" alignItems="center" gap="3" mt="3">
        <Box>
          <Text>{data.length} total results</Text>
        </Box>
        <Spacer />
        <Box>
          <Text>
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </Text>
        </Box>
        <ButtonGroup isAttached>
          <IconButton
            aria-label="First page"
            icon={<FontAwesomeIcon icon={faAnglesLeft} />}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          />
          <IconButton
            aria-label="Previous page"
            icon={<FontAwesomeIcon icon={faAngleLeft} />}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          />
          <IconButton
            aria-label="Next page"
            icon={<FontAwesomeIcon icon={faAngleRight} />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
          <IconButton
            aria-label="Last page"
            icon={<FontAwesomeIcon icon={faAnglesRight} />}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          />
        </ButtonGroup>
        <Box>
          <Text>Go to page:</Text>
        </Box>
        <Box>
          <NumberInput
            min={1}
            max={table.getPageCount()}
            maxW={20}
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(valueAsString, valueAsNumber) => {
              const page = valueAsNumber ? valueAsNumber - 1 : 0;
              table.setPageIndex(page);
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box>
          <Select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
    </Box>
  );
}

export default DataTable;
