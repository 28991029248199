import { IStepResults, StepStatus } from '../stepResults/types';

export type Plan = 'regular' | 'pro' | 'brand';
export type SellerType = 'dealer' | 'distributor';
export type Currency = 'USD' | 'CAD';
export type Country = 'US' | 'CA';
export type ProcessStage = 'update' | 'add';

export const PROCESS_STATUS: { [key: number]: string } = {
  0: 'Waiting',
  1: 'Running',
  2: 'Complete',
};

export interface IWebsite {
  id: number;
  server_id: number;
  database_name: string;
  domain: string;
  is_dev_site: boolean;
  plan: Plan;
  seller_type: SellerType;
  currency: Currency;
  country: Country;
  total_products: number;
  total_processes: number;
  process_id: null | number;
  process_status: number;
  process_stage: ProcessStage;
  process_progress: number;
  num_processed: number;
  num_to_process: number;
  last_id: null | number;
  process_log: null | string;
  date_started: null | string;
  last_modified: null | string;
}

export interface IPleskDatabase {
  default_user_id: number;
  id: number;
  name: string;
  parent_domain: number;
  server_id: number;
  type: string;
}

export interface IPleskDatabaseServer {
  db_count: number;
  host: string;
  id: number;
  is_default: boolean;
  is_local: boolean;
  port: number;
  status: string;
  type: string;
}

export interface IPleskDatabaseUser {
  database_id: number;
  id: number;
  login: string;
}

export interface IPleskDomain {
  guid: string;
  id: number;
}

export interface ICreateWebsiteRequest {
  action: string;
  country: Country;
  create_dns_record: boolean;
  currency: Currency;
  db_password: string;
  domain: string;
  ftp_password: string;
  is_dev_site: boolean;
  secure_www: boolean;
  plan: Plan;
  seller_type: SellerType;
  server_id: number;
}

export interface ICreateWebsiteResponse {
  status: StepStatus;
  success: boolean;
  error?: string;
  critical?: boolean;
  pleskDomain?: IPleskDomain;
  pleskDatabase?: IPleskDatabase;
}

export interface IChangeDomainRequest {
  id: number;
  domain: string;
  domain_alias: string;
  secure_www: boolean;
}

export interface IChangeDomainResponse {
  success: boolean;
  status: string;
  oldDomain: string;
  newDomain: string;
}
