import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { ILocations } from './types';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['Locations'],
  endpoints: (builder) => ({
    getLocations: builder.query<ILocations, void>({
      query: () => ({
        url: 'location',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Locations', id: 'LIST' }],
    }),
  }),
});

export const { useGetLocationsQuery } = locationApi;
