import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Alert,
  AlertIcon,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISiteUser } from './types';
import { deleteSiteUserSchema } from './validationSchema';
import { useDeleteSiteUserMutation } from './siteUserApi';
import ConfirmDeleteModal from '../confirmDeleteModal/ConfirmDeleteModal';

function DeleteSiteUserForm() {
  const toast = useToast();
  const [deleteSiteUser] = useDeleteSiteUserMutation();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<Partial<ISiteUser>>({
    resolver: yupResolver(deleteSiteUserSchema),
  });

  const watchUsername = watch('username');

  const onSubmit = async (values: Partial<ISiteUser>) => {
    try {
      const { username, count } = await deleteSiteUser(values).unwrap();

      toast({
        title: `Success! The user ${username} was deleted from ${count} sites!`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });

      reset();
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const deleteAction = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <form>
      <Alert status="info" mb="3" rounded="md">
        <AlertIcon />
        The user will be deleted from all current sites. A change must be made to the install file to delete the user
        from future sites.
        <br />
        Please, notify a developer of the username you delete!
      </Alert>
      <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
        <FormControl isRequired isInvalid={!!errors.username}>
          <FormLabel htmlFor="deleteUsername">Username</FormLabel>
          <Input id="deleteUsername" placeholder="Username" {...register('username')} />
          <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <ConfirmDeleteModal
        buttonLabel="Submit"
        modalHeading={`Delete site user ${watchUsername}?`}
        deleteAction={deleteAction}
        mt={6}
        isLoading={isSubmitting}
      />
    </form>
  );
}

export default DeleteSiteUserForm;
