import { useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { IFormValues } from './types';
import GlobalInstantRebateForm from './GlobalInstantRebateForm';
import { useGetGlobalInstantRebateQuery, useUpdateGlobalInstantRebateMutation } from './globalInstantRebateApi';

function EditGlobalInstantRebate() {
  const { uuid } = useParams();
  const { data: globalInstantRebate } = useGetGlobalInstantRebateQuery(uuid ?? skipToken);
  const [updateGlobalInstantRebate] = useUpdateGlobalInstantRebateMutation();
  const toast = useToast();
  const fullName = globalInstantRebate ? globalInstantRebate.name : '';
  const heading = `Edit Global Instant Rebate${fullName ? `: ${fullName}` : ''}`;

  const onSubmit = async (values: IFormValues) => {
    try {
      const res = await updateGlobalInstantRebate({ ...globalInstantRebate, ...values }).unwrap();
      const success = !!res.uuid;

      toast({
        title: success
          ? 'Successfully updated the global instant rebate!'
          : 'Failed to update the global instant rebate!',
        status: success ? 'success' : 'error',
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      toast({
        title: 'Failed to update the global instant rebate!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return <GlobalInstantRebateForm heading={heading} onSubmit={onSubmit} globalInstantRebate={globalInstantRebate} />;
}

export default EditGlobalInstantRebate;
