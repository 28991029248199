import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import theme from './theme';
import { store } from './app/store';

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </Provider>
  );
}

export default Providers;
