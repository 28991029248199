import { Tag, TagProps, useColorMode } from '@chakra-ui/react';

function OrderChartTag({ children }: TagProps) {
  const { colorMode } = useColorMode();
  const bg = colorMode === 'dark' ? '#2e65cd' : '#a6c5ff';
  const textColor = colorMode === 'dark' ? '#ffffff' : '#38428d';

  return (
    <Tag m="1" px="5" size="lg" bg={bg} textColor={textColor} borderRadius="full" boxShadow="sm">
      {children}
    </Tag>
  );
}

export default OrderChartTag;
