import { useEffect } from 'react';
import {
  Button,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validationSchema from './validationSchema';
import { IFormValues, ISystemUser } from './types';
import { permissions } from '../../app/permissions';
import ContentContainer from '../layout/ContentContainer';

interface IProps {
  heading: string;
  onSubmit: (values: IFormValues) => void;
  systemUser?: ISystemUser;
}

function SystemUserForm({ heading, onSubmit, systemUser }: IProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
    control,
    setValue,
  } = useForm<IFormValues>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (systemUser) {
      reset(systemUser);
    }
  }, [systemUser, reset]);

  const selectAllPermissions = () => {
    setValue(
      'permissions',
      permissions.map(({ name }) => name),
    );
  };

  return (
    <ContentContainer heading={heading}>
      <Button
        as={Link}
        to="/system-user"
        variant="ghost"
        colorScheme="blue"
        aria-label="All System Users"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        All System Users
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <FormControl isRequired isInvalid={!!errors.insight_id}>
            <FormLabel htmlFor="insight_id">Insight ID</FormLabel>
            <Input id="insight_id" placeholder="Insight ID" {...register('insight_id')} />
            <FormErrorMessage>{errors.insight_id && errors.insight_id.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input id="email" placeholder="Email" {...register('email')} />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.first_name}>
            <FormLabel htmlFor="first_name">First Name</FormLabel>
            <Input id="first_name" placeholder="First Name" {...register('first_name')} />
            <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.last_name}>
            <FormLabel htmlFor="last_name">Last Name</FormLabel>
            <Input id="last_name" placeholder="Last Name" {...register('last_name')} />
            <FormErrorMessage>{errors.last_name && errors.last_name.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.permissions}>
            <FormLabel>Permissions</FormLabel>
            <Button size="sm" variant="link" colorScheme="blue" onClick={selectAllPermissions} mb={3}>
              Select All
            </Button>
            <Stack spacing={3} direction="column">
              <Controller
                name="permissions"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <CheckboxGroup {...rest}>
                    {permissions.map(({ name, label }) => (
                      <Checkbox value={name} key={name}>
                        {label}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                )}
              />
            </Stack>
            <FormErrorMessage>{errors.permissions && errors.permissions.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Button mt={6} colorScheme="green" isLoading={isSubmitting} type="submit">
          Submit
        </Button>
      </form>
    </ContentContainer>
  );
}

SystemUserForm.defaultProps = {
  systemUser: undefined,
};

export default SystemUserForm;
