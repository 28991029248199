import * as yup from 'yup';

export default yup.object().shape({
  insight_id: yup.number().integer().required().typeError('Insight ID must be an integer.'),
  first_name: yup
    .string()
    .min(2, 'First Name must contain least 2 characters.')
    .max(255, 'First Name cannot contain more than 255 characters.')
    .required(),
  last_name: yup
    .string()
    .min(2, 'Last Name must contain least 2 characters.')
    .max(255, 'Last Name cannot contain more than 255 characters.')
    .required('Last Name is required.'),
  email: yup.string().email('Email must be valid.').required(),
  permissions: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one permission is required.')
    .required('At least one permission is required.'),
});
