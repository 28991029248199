import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { IDeploymentRequest, IDeployment } from './types';

export const deploymentApi = createApi({
  reducerPath: 'deploymentApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  endpoints: (builder) => ({
    createDeployment: builder.mutation<IDeployment, IDeploymentRequest>({
      query: (data) => ({
        url: 'deployment',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useCreateDeploymentMutation } = deploymentApi;
