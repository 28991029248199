import { Box, Center, Stack, Text } from '@chakra-ui/react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { useState } from 'react';
import ContentContainer from '../layout/ContentContainer';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';
import { useGetLocationsQuery } from './locationApi';
import { IAdditionalLocation, IPrimaryLocation, isPrimaryLocation } from './types';

function ClientMap() {
  const { data, isLoading } = useGetLocationsQuery();
  const containerStyle = { width: '100%', height: '850px' };
  const zoom = 5;
  const center: google.maps.LatLngLiteral = { lat: 42, lng: -97 };
  const [selectedLocation, setSelectedLocation] = useState<IPrimaryLocation | IAdditionalLocation | undefined>();

  return (
    <ContentContainer heading="Client Map" fullWidth>
      {isLoading && (
        <Center>
          <ThemedSyncLoader />
        </Center>
      )}
      {data && (
        <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
            {data.primary
              .filter(({ lat, lng }) => lat && lng)
              .map((location) => (
                <Marker
                  key={location.unique_key}
                  position={{ lat: location.lat, lng: location.lng }}
                  onClick={() => setSelectedLocation(location)}
                />
              ))}
            {data.additional.map((location) => (
              <Marker
                key={location.unique_key}
                position={{ lat: location.lat, lng: location.lng }}
                onClick={() => setSelectedLocation(location)}
              />
            ))}
            {selectedLocation && (
              <InfoWindow
                position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                onCloseClick={() => setSelectedLocation(undefined)}
              >
                <Box textColor="black">
                  <Stack spacing="2">
                    <Text fontWeight="bold">{selectedLocation.location_name}</Text>
                    {isPrimaryLocation(selectedLocation) ? (
                      <Text>{selectedLocation.address}</Text>
                    ) : (
                      <>
                        <Text>{selectedLocation.street}</Text>
                        <Text>
                          {selectedLocation.city}, {selectedLocation.state} {selectedLocation.zip}
                        </Text>
                      </>
                    )}
                    <Text>{selectedLocation.telephone}</Text>
                  </Stack>
                </Box>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      )}
    </ContentContainer>
  );
}

export default ClientMap;
