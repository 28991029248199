import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { ISiteUser } from './types';

interface ICreateResponse {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  username: string;
  count: number;
  errors: string[];
}

interface IDeleteResponse {
  username: string;
  count: number;
  errors: string[];
}

export const siteUserApi = createApi({
  reducerPath: 'siteUserApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  endpoints: (builder) => ({
    createSiteUser: builder.mutation<ICreateResponse, ISiteUser>({
      query: (data) => ({
        url: 'site-user',
        method: 'POST',
        data,
      }),
    }),
    deleteSiteUser: builder.mutation<IDeleteResponse, Partial<ISiteUser>>({
      query: ({ username }) => ({
        url: `site-user/${username}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateSiteUserMutation, useDeleteSiteUserMutation } = siteUserApi;
