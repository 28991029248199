import {
  SimpleGrid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Switch,
  Button,
  Tooltip,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  useColorMode,
  ButtonGroup,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useEffect, useState } from 'react';
import ContentContainer from '../layout/ContentContainer';
import { useCreateWebsiteMutation } from './websiteApi';
import { ICreateWebsiteRequest } from './types';
import { websiteValidationSchema } from './validationSchema';
import generatePleskPassword from '../../helpers/generatePleskPassword';
import { useGetServersQuery } from '../server/serverApi';

function CreateWebsite() {
  const { colorMode } = useColorMode();
  const [isBuilding, setIsBuilding] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { data: servers } = useGetServersQuery();
  const [createWebsite, { reset }] = useCreateWebsiteMutation();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset: resetForm,
    setValue,
  } = useForm<ICreateWebsiteRequest>({
    resolver: yupResolver(websiteValidationSchema),
    defaultValues: {
      db_password: generatePleskPassword(),
      ftp_password: generatePleskPassword(),
    },
  });

  useEffect(() => {
    if (servers) {
      setValue('server_id', Number(servers[0].server_id));
    }
  }, [servers, setValue]);

  const onReset = () => {
    resetForm();
    reset();
    setValue('db_password', generatePleskPassword());
    setValue('ftp_password', generatePleskPassword());
    setIsBuilding(false);
    setIsComplete(false);
  };

  const onSubmit = async (values: ICreateWebsiteRequest) => {
    let criticalError = false;

    const doAction = async (action: string) => {
      if (criticalError) {
        return;
      }

      try {
        await createWebsite({ ...values, action }).unwrap();
      } catch (error) {
        criticalError = true;
      }
    };

    setIsBuilding(true);
    setIsComplete(false);

    if (values.create_dns_record) {
      await doAction('createARecord');
    }

    await doAction('createSite');
    setIsBuilding(false);
    setIsComplete(true);
  };

  return (
    <ContentContainer heading="Create Website">
      <Button
        as={RouterLink}
        to="/website"
        variant="ghost"
        colorScheme="blue"
        aria-label="All Websites"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        All Websites
      </Button>
      {isComplete && (
        <>
          <Alert status="info" mb="3" variant="solid" rounded="md">
            <AlertIcon />
            <AlertDescription>
              The website is starting to build. You&apos;ll receive an email when it&apos;s complete.
            </AlertDescription>
          </Alert>
          <Alert status="warning" mb="3" variant="solid" rounded="md">
            <AlertIcon />
            <AlertDescription>
              <strong>NOTE:</strong> This tool does not set up a scheduled backup of the website. You must do this
              manually in Plesk.
            </AlertDescription>
          </Alert>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="3">
          <FormControl isRequired isInvalid={!!errors.domain}>
            <FormLabel htmlFor="domain">Domain</FormLabel>
            <Input id="domain" placeholder="Domain" {...register('domain')} autoFocus />
            <FormErrorMessage>{errors.domain && errors.domain.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.server_id}>
            <FormLabel htmlFor="server_id">Server</FormLabel>
            <Select id="server_id" {...register('server_id')}>
              {servers
                ? servers.map(({ server_id, name, ip }) => (
                    <option key={server_id} value={server_id}>
                      {name} ({ip})
                    </option>
                  ))
                : null}
            </Select>
            <FormErrorMessage>{errors.server_id && errors.server_id.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3" mt="3">
          <FormControl display="flex" alignItems="center">
            <Switch id="create_dns_record" {...register('create_dns_record')} />
            <FormLabel htmlFor="create_dns_record" mb="0" ml="3">
              Create DNS record?
            </FormLabel>
            <Tooltip
              label="Select this if the website will be a subdomain of aftermarketwebsites.com."
              rounded="md"
              placement="right-start"
              hasArrow
            >
              <Text display="inline-block" color={colorMode === 'dark' ? 'blue.200' : 'blue.600'}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Text>
            </Tooltip>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <Switch id="secure_www" {...register('secure_www')} />
            <FormLabel htmlFor="secure_www" mb="0" ml="3">
              Secure WWW with SSL certificate?
            </FormLabel>
            <Tooltip
              label="Select this if a www CNAME has been set up for the domain. The SSL certificate may fail if this is enabled without a CNAME record existing."
              rounded="md"
              placement="right-start"
              hasArrow
            >
              <Text display="inline-block" color={colorMode === 'dark' ? 'blue.200' : 'blue.600'}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Text>
            </Tooltip>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <Switch id="is_dev_site" {...register('is_dev_site')} />
            <FormLabel htmlFor="is_dev_site" mb="0" ml="3">
              Dev/test site?
            </FormLabel>
            <Tooltip
              label={`
                Dev sites do not run off of the live codebase and are not updated with new releases.
                Enable this if the site will be used for testing or development purposes.
                NOTE: this is not related to the Development site type in any way.
                `}
              rounded="md"
              placement="right-start"
              hasArrow
            >
              <Text display="inline-block" color={colorMode === 'dark' ? 'blue.200' : 'blue.600'}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Text>
            </Tooltip>
          </FormControl>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="3">
            <FormControl isRequired isInvalid={!!errors.plan}>
              <FormLabel htmlFor="plan">Plan</FormLabel>
              <Select id="plan" placeholder="Select plan" {...register('plan')}>
                <option value="brand">Brand</option>
                <option value="pro">Pro</option>
                <option value="regular">Regular</option>
              </Select>
              <FormErrorMessage>{errors.plan && errors.plan.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.seller_type}>
              <FormLabel htmlFor="seller_type">Site Type</FormLabel>
              <Select id="seller_type" placeholder="Select site type" {...register('seller_type')}>
                <option value="brand">Brand</option>
                <option value="dealer">Dealer</option>
                <option value="development">Development</option>
                <option value="distributor">Distributor</option>
              </Select>
              <FormErrorMessage>{errors.seller_type && errors.seller_type.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="3">
            <FormControl isRequired isInvalid={!!errors.currency}>
              <FormLabel htmlFor="currency">Currency</FormLabel>
              <Select id="currency" placeholder="Select currency" {...register('currency')}>
                <option value="USD">USD</option>
                <option value="CAD">CAD</option>
              </Select>
              <FormErrorMessage>{errors.currency && errors.currency.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.country}>
              <FormLabel htmlFor="country">Country</FormLabel>
              <Select id="country" placeholder="Select country" {...register('country')}>
                <option value="US">US</option>
                <option value="CA">CA</option>
              </Select>
              <FormErrorMessage>{errors.country && errors.country.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button
            colorScheme="green"
            disabled={isBuilding || isSubmitting || isComplete}
            isLoading={isBuilding || isSubmitting}
            type="submit"
          >
            Submit
          </Button>
          <Button colorScheme="gray" disabled={isBuilding || isSubmitting} type="button" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
      </form>
    </ContentContainer>
  );
}

export default CreateWebsite;
