import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISiteUser } from './types';
import { createSiteUserSchema } from './validationSchema';
import { useCreateSiteUserMutation } from './siteUserApi';
import PasswordInput from '../password/PasswordInput';

function AddSiteUserForm() {
  const toast = useToast();
  const [createSiteUser] = useCreateSiteUserMutation();

  const formMethods = useForm<ISiteUser>({
    resolver: yupResolver(createSiteUserSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = formMethods;

  const onSubmit = async (values: ISiteUser) => {
    try {
      const { username, count } = await createSiteUser(values).unwrap();

      toast({
        title: `Success! The user ${username} was added to ${count} sites!`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });

      reset();
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Alert status="info" mb="3" rounded="md">
        <AlertIcon />
        The user will be added to all current sites. A change must be made to the install file to add the user to future
        sites.
        <br />
        Please, notify a developer of the username you add!
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <FormControl isRequired isInvalid={!!errors.username}>
            <FormLabel htmlFor="username">Username</FormLabel>
            <Input id="username" placeholder="Username" {...register('username')} />
            <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.password}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <PasswordInput placeholder="Password" name="password" />
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input id="email" placeholder="Email" {...register('email')} />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.first_name}>
            <FormLabel htmlFor="first_name">First Name</FormLabel>
            <Input id="first_name" placeholder="First Name" {...register('first_name')} />
            <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.last_name}>
            <FormLabel htmlFor="last_name">Last Name</FormLabel>
            <Input id="last_name" placeholder="Last Name" {...register('last_name')} />
            <FormErrorMessage>{errors.last_name && errors.last_name.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Button mt={6} colorScheme="green" isLoading={isSubmitting} type="submit">
          Submit
        </Button>
      </form>
    </FormProvider>
  );
}

export default AddSiteUserForm;
