import { Table, TableCaption, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react';
import ContentContainer from '../../layout/ContentContainer';
import AllScriptsButton from '../AllScriptsButton';
import { useUserLoginsQuery } from '../scriptApi';
import ScriptResults from '../ScriptResults';

function UserLogins() {
  const { data, isLoading, isUninitialized, isSuccess, isError } = useUserLoginsQuery();

  return (
    <ContentContainer heading="User Logins (Past Month)">
      <AllScriptsButton />
      <ScriptResults isUninitialized={isUninitialized} isLoading={isLoading} isSuccess={isSuccess} isError={isError}>
        {isSuccess && data && (
          <Table variant="simple" size="sm">
            <TableCaption>Logins by non-AW users in the past month.</TableCaption>
            <Thead>
              <Tr>
                <Th>Domain</Th>
                <Th isNumeric># of Users</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map(({ domain, count }) => (
                <Tr key={domain}>
                  <Td>{domain}</Td>
                  <Td isNumeric>{count}</Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Domain</Th>
                <Th isNumeric># of Users</Th>
              </Tr>
            </Tfoot>
          </Table>
        )}
      </ScriptResults>
    </ContentContainer>
  );
}

export default UserLogins;
