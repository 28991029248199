import { Text, TextProps } from '@chakra-ui/react';

function PopoverLabel({ children }: TextProps) {
  return (
    <Text textAlign="right" fontWeight="bold">
      {children}
    </Text>
  );
}

export default PopoverLabel;
