/* eslint-disable react/no-unstable-nested-components */
import { Center, Button, Tooltip, IconButton, useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPenToSquare } from '@fortawesome/pro-duotone-svg-icons';
import CustomButtonGroup from '../buttonGroup/CustomButtonGroup';
import ContentContainer from '../layout/ContentContainer';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';
import DataTable from '../table/DataTable';
import { IBrandFilter } from './types';
import ConfirmDeleteModal from '../confirmDeleteModal/ConfirmDeleteModal';
import { useDeleteBrandFilterMutation, useGetBrandFiltersQuery } from './brandApi';

function BrandFilters() {
  const toast = useToast();
  const { data, isLoading } = useGetBrandFiltersQuery();
  const [deleteBrandFilter] = useDeleteBrandFilterMutation();

  const columns = useMemo<ColumnDef<IBrandFilter>[]>(
    () => [
      {
        header: 'Name',
        cell: ({ row }) => row.original.name,
      },
      {
        header: 'Brands',
        cell: ({ row }) => Object.keys(row.original.brands).length,
      },
      {
        header: 'Action',
        id: 'action',
        meta: {
          isNumeric: true,
        },
        cell: ({ row }) => {
          const { id } = row.original;
          const { name } = row.original;

          const deleteAction = async () => {
            const res = await deleteBrandFilter(id).unwrap();

            if (res.success) {
              toast({
                title: `Successfully deleted ${name}!`,
                status: 'success',
                isClosable: true,
                position: 'top',
              });
            } else {
              toast({
                title: `Failed to delete ${name}!`,
                status: 'error',
                isClosable: true,
                position: 'top',
              });
            }
          };

          return (
            <>
              <Tooltip label="Edit Custom Filter" placement="left" hasArrow>
                <IconButton
                  as={Link}
                  to={`/brand/filter/${id}`}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="Edit Custom Filter"
                  icon={<FontAwesomeIcon icon={faPenToSquare} />}
                  data-testid={`edit-brand-filter-${id}`}
                />
              </Tooltip>
              <ConfirmDeleteModal
                iconButton
                buttonLabel="Delete Custom Filter"
                modalHeading={`Delete ${name}?`}
                deleteAction={deleteAction}
                testId={`delete-brand-filter-${id}`}
              />
            </>
          );
        },
      },
    ],
    [deleteBrandFilter, toast],
  );

  return (
    <ContentContainer heading="Custom Filters">
      <CustomButtonGroup mb="6">
        <Button
          as={Link}
          to="/brand"
          variant="ghost"
          colorScheme="blue"
          aria-label="Back to Brands"
          leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Back to Brands
        </Button>
        <Button as={Link} to="/brand/filter/add" colorScheme="green">
          Add Custom Filter
        </Button>
      </CustomButtonGroup>
      {isLoading && (
        <Center>
          <ThemedSyncLoader />
        </Center>
      )}
      {data && <DataTable columns={columns} data={data} />}
    </ContentContainer>
  );
}

export default BrandFilters;
