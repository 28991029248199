import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import SidebarMenu from './SidebarMenu';

function MobileMenu({ display }: BoxProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box display={display}>
      <IconButton
        aria-label="Open Menu"
        bg="blackAlpha.100"
        onClick={onOpen}
        icon={<FontAwesomeIcon icon={faBars} />}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <SidebarMenu display="flex" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default MobileMenu;
