import { useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useGetSystemUserQuery, useUpdateSystemUserMutation } from './systemUserApi';
import { IFormValues } from './types';
import SystemUserForm from './SystemUserForm';

function EditSystemUser() {
  const { systemUserId } = useParams();
  const { data: systemUser } = useGetSystemUserQuery(Number(systemUserId));
  const [updateSystemUser] = useUpdateSystemUserMutation();
  const toast = useToast();
  const fullName = systemUser ? `${systemUser.first_name} ${systemUser.last_name}` : '';
  const heading = `Edit System User${fullName ? `: ${fullName}` : ''}`;

  const onSubmit = async (values: IFormValues) => {
    try {
      const res = await updateSystemUser({ ...systemUser, ...values }).unwrap();
      const success = !!res.id;

      toast({
        title: success ? 'Successfully updated the system user!' : 'Failed to update the system user!',
        status: success ? 'success' : 'error',
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      toast({
        title: 'Failed to update the system user!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return <SystemUserForm heading={heading} onSubmit={onSubmit} systemUser={systemUser} />;
}

export default EditSystemUser;
