import * as yup from 'yup';

export const brandListValidationSchema = yup.object().shape({
  enabled: yup.array().of(
    yup.object().shape({
      code: yup.string(),
      name: yup.string(),
    }),
  ),
  disabled: yup.array().of(
    yup.object().shape({
      code: yup.string(),
      name: yup.string(),
    }),
  ),
});

export const brandFilterValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Name is required.'),
  enabled: yup.array().of(
    yup.object().shape({
      code: yup.string(),
      name: yup.string(),
    }),
  ),
  disabled: yup.array().of(
    yup.object().shape({
      code: yup.string(),
      name: yup.string(),
    }),
  ),
});
