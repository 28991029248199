import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCreateSystemUserMutation } from './systemUserApi';
import { IFormValues } from './types';
import SystemUserForm from './SystemUserForm';

function AddSystemUser() {
  const [createSystemUser] = useCreateSystemUserMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (values: IFormValues) => {
    try {
      const res = await createSystemUser(values).unwrap();
      const success = !!res.id;

      toast({
        title: success ? 'Successfully created the system user!' : 'Failed to create the system user!',
        status: success ? 'success' : 'error',
        isClosable: true,
        position: 'top',
      });

      if (success) {
        navigate(`/system-user/${res.id}`);
      }
    } catch (error) {
      toast({
        title: 'Failed to create the system user!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return <SystemUserForm heading="Add System User" onSubmit={onSubmit} />;
}

export default AddSystemUser;
