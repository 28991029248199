import * as yup from 'yup';

const usernameValidation = yup
  .string()
  .min(4, 'Username must contain at least 4 characters.')
  .max(255, 'Username cannot contain more than 255 characters.')
  .required();

export const createSiteUserSchema = yup.object().shape({
  username: usernameValidation,
  first_name: yup
    .string()
    .min(2, 'First Name must contain at least 2 characters.')
    .max(255, 'First Name cannot contain more than 255 characters.')
    .required(),
  last_name: yup
    .string()
    .min(2, 'Last Name must contain at least 2 characters.')
    .max(255, 'Last Name cannot contain more than 255 characters.')
    .required('Last Name is required.'),
  email: yup.string().email('Email must be valid.').required(),
  password: yup.string().min(12, 'Password must contain at least 12 characters.').required('Password is required.'),
});

export const deleteSiteUserSchema = yup.object().shape({
  username: usernameValidation,
});
