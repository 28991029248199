import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Text,
  Center,
  Switch,
  Tooltip,
  useColorMode,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useState } from 'react';
import { IChangeDomainRequest } from './types';
import { useChangeDomainMutation, useGetWebsiteQuery } from './websiteApi';
import { changeDomainValidationSchema } from './validationSchema';
import ContentContainer from '../layout/ContentContainer';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';

function ChangeDomain() {
  const [isComplete, setIsComplete] = useState(false);
  const { colorMode } = useColorMode();
  const params = useParams();
  const websiteId = params.websiteId ? Number(params.websiteId) : undefined;
  const { data: website, isLoading: isLoadingWebsite } = useGetWebsiteQuery(websiteId ?? skipToken);
  const [changeDomain] = useChangeDomainMutation();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IChangeDomainRequest>({
    resolver: yupResolver(changeDomainValidationSchema),
  });

  const onSubmit = async (values: IChangeDomainRequest) => {
    if (!websiteId) {
      return;
    }

    setIsComplete(false);

    try {
      await changeDomain({
        ...values,
        id: websiteId,
      }).unwrap();

      setIsComplete(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContentContainer heading={!website ? 'Change Domain' : `Change Domain for ${website.domain}`}>
      <Button
        as={RouterLink}
        to="/website"
        variant="ghost"
        colorScheme="blue"
        aria-label="All Websites"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        All Websites
      </Button>
      {!isLoadingWebsite && !alert && !website && <Text>Website not found.</Text>}
      {isLoadingWebsite && (
        <Center>
          <ThemedSyncLoader />
        </Center>
      )}
      {isComplete && (
        <Alert status="info" mb="3" variant="solid" rounded="md">
          <AlertIcon />
          <AlertDescription>
            Hang tight! We&apos;re starting to change the domain now. You&apos;ll receive an email when it&apos;s
            complete.
          </AlertDescription>
        </Alert>
      )}
      {!isLoadingWebsite && website && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
            <FormControl isRequired isInvalid={!!errors.domain}>
              <FormLabel htmlFor="domain">New Domain</FormLabel>
              <Input id="domain" placeholder="example.com" {...register('domain')} />
              <FormErrorMessage>{errors.domain && errors.domain.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.domain_alias}>
              <FormLabel htmlFor="domain_alias">Domain Alias (optional)</FormLabel>
              <Input id="domain_alias" placeholder="example.aftermarketwebsites.com" {...register('domain_alias')} />
              <FormErrorMessage>{errors.domain_alias && errors.domain_alias.message}</FormErrorMessage>
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <Switch id="secure_www" {...register('secure_www')} />
              <FormLabel htmlFor="secure_www" mb="0" ml="3">
                Secure WWW with SSL certificate?
              </FormLabel>
              <Tooltip
                label="Select this if a www CNAME has been set up for the domain. The SSL certificate may fail if this is enabled without a CNAME record existing."
                rounded="md"
                placement="right-start"
                hasArrow
              >
                <Text display="inline-block" color={colorMode === 'dark' ? 'blue.200' : 'blue.600'}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Text>
              </Tooltip>
            </FormControl>
          </SimpleGrid>
          <Button mt={6} colorScheme="green" isLoading={isSubmitting} type="submit">
            Submit
          </Button>
        </form>
      )}
    </ContentContainer>
  );
}

export default ChangeDomain;
