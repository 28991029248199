export default (length: number): string => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$_-';
  let value = '';

  for (let i = 0, n = charset.length; i < length; i += 1) {
    value += charset.charAt(Math.floor(Math.random() * n));
  }

  return value;
};
