import * as yup from 'yup';

export default yup.object().shape({
  name: yup
    .string()
    .min(2, 'Name must contain least 2 characters.')
    .max(255, 'Name cannot contain more than 255 characters.')
    .required(),
  type: yup.string().length(1, 'Type must contain 1 character.').required('Type is required.'),
  discount: yup.number().positive('Discount must be a positive number.').required(),
  date_start: yup.string().required('Start date is required.'),
  date_end: yup.string().required('End date is required.'),
  link_selections: yup.boolean(),
  use_freeform_parts: yup.boolean(),
});
