/* eslint-disable react/no-unstable-nested-components */
import { Link, Center, Button, useColorMode } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import CustomButtonGroup from '../buttonGroup/CustomButtonGroup';
import ContentContainer from '../layout/ContentContainer';
import { useGetWebsitesQuery } from '../website/websiteApi';
import { IWebsite } from '../website/types';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';
import DataTable from '../table/DataTable';
import { useLoginQuery } from '../auth/authApi';

function Brands() {
  const { colorMode } = useColorMode();
  const linkColor = colorMode === 'dark' ? 'blue.300' : 'blue.600';
  const { data: currentUser } = useLoginQuery();
  const { data, isLoading } = useGetWebsitesQuery();
  const permissions = currentUser ? currentUser.authenticatedUser.permissions : [];
  const canEditBulk = permissions.includes('manage_bulk_brands');
  const canEditUnavailable = permissions.includes('manage_unavailable_brands');
  const canEditGlobal = permissions.includes('manage_global_brands');
  const showButtonGroup = canEditBulk || canEditUnavailable || canEditGlobal;

  const columns = useMemo<ColumnDef<IWebsite>[]>(
    () => [
      {
        header: 'Domain',
        accessorFn: ({ domain }) => domain.toLowerCase(),
        cell: ({ row }) => {
          const domain = row.original.domain.toLowerCase();

          return (
            <Link href={`https://${domain}/`} color={linkColor} isExternal>
              {domain}
            </Link>
          );
        },
      },
      {
        header: 'Action',
        id: 'action',
        meta: {
          isNumeric: true,
        },
        cell: ({ row }) => (
          <Button as={RouterLink} to={`/brand/${row.original.id}`} colorScheme="blue" variant="link">
            Edit Brands
          </Button>
        ),
      },
    ],
    [linkColor],
  );

  return (
    <ContentContainer heading="Brands">
      {showButtonGroup && (
        <CustomButtonGroup mb="6">
          {canEditBulk && (
            <Button as={RouterLink} to="/brand/bulk" colorScheme="blue">
              Bulk Edit Brands
            </Button>
          )}
          {canEditUnavailable && (
            <Button as={RouterLink} to="/brand/unavailable" colorScheme="orange">
              Edit Unavailable Brands
            </Button>
          )}
          {canEditGlobal && (
            <Button as={RouterLink} to="/brand/global" colorScheme="red">
              Edit Global Brands
            </Button>
          )}
          {canEditGlobal && (
            <Button as={RouterLink} to="/brand/filter" colorScheme="teal">
              Custom Filters
            </Button>
          )}
        </CustomButtonGroup>
      )}
      {isLoading && (
        <Center>
          <ThemedSyncLoader />
        </Center>
      )}
      {data && <DataTable columns={columns} data={data} />}
    </ContentContainer>
  );
}

export default Brands;
