import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  useToast,
  FormHelperText,
  Image,
  Heading,
  Stack,
  useColorMode,
  Code,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-duotone-svg-icons';
import ContentContainer from '../../layout/ContentContainer';
import AllScriptsButton from '../AllScriptsButton';
import { useSettingUsageMutation } from '../scriptApi';
import { ISettingUsage } from './types';
import ScriptResults from '../ScriptResults';
import WebsiteResults from '../WebsiteResults';
import step1Img from './setting_name_1.png';
import step2Img from './setting_name_2.png';

function SettingUsage() {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [settingUsage, { data, isUninitialized, isLoading, isSuccess, isError, reset: resetData }] =
    useSettingUsageMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset: resetForm,
  } = useForm<ISettingUsage>({
    resolver: yupResolver(
      yup.object().shape({
        setting: yup.string().required('Setting name is required.'),
      }),
    ),
  });

  const onSubmit = async (values: ISettingUsage) => {
    try {
      await settingUsage(values);
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const onReset = () => {
    resetForm();
    resetData();
  };

  return (
    <ContentContainer heading="Setting Usage">
      <AllScriptsButton />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="3">
          <FormControl isRequired isInvalid={!!errors.setting}>
            <FormLabel htmlFor="setting">Setting Name</FormLabel>
            <Input id="setting" placeholder="Setting Name" {...register('setting')} />
            <FormHelperText>
              <Popover placement="right-start">
                <PopoverTrigger>
                  <Button variant="link" colorScheme="blue" display="flex" gap="3">
                    <FontAwesomeIcon icon={faCircleQuestion} /> <Text>How to find a setting name</Text>
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  boxShadow="xl"
                  w="auto"
                  maxW={{ base: '400px', lg: '550px' }}
                  textColor={colorMode === 'dark' ? 'blue.100' : 'blue.800'}
                >
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    <Heading as="h3" size="md">
                      How to find a setting name
                    </Heading>
                  </PopoverHeader>
                  <PopoverBody>
                    <Stack spacing="6">
                      <Text fontSize="md" fontWeight="bold">
                        Step 1: right click on the setting input (e.g. textbox, toggle, etc.) and click
                        &ldquo;Inspect&rdquo; to open the dev tools
                      </Text>
                      <Image
                        boxSize="auto"
                        objectFit="cover"
                        borderRadius="md"
                        src={step1Img}
                        alt="Step 1 for finding a setting name"
                      />
                      <Text fontSize="md" fontWeight="bold">
                        Step 2: copy the text in the &ldquo;name&rdquo; attribute. In this example,{' '}
                        <Code>config_website_plan</Code> is the setting name.
                      </Text>
                      <Image
                        boxSize="auto"
                        objectFit="cover"
                        borderRadius="md"
                        src={step2Img}
                        alt="Step 2 for finding a setting name"
                      />
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </FormHelperText>
            <FormErrorMessage>{errors.setting && errors.setting.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.value}>
            <FormLabel htmlFor="value">Contains Value (optional)</FormLabel>
            <Input id="value" placeholder="Contains Value" {...register('value')} />
            <FormErrorMessage>{errors.value && errors.value.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button colorScheme="green" isLoading={isLoading} type="submit">
            Submit
          </Button>
          <Button colorScheme="gray" disabled={isLoading} type="button" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <ScriptResults isUninitialized={isUninitialized} isLoading={isLoading} isSuccess={isSuccess} isError={isError}>
          {isSuccess && data && <WebsiteResults results={data} />}
        </ScriptResults>
      </form>
    </ContentContainer>
  );
}

export default SettingUsage;
