import { Button } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import ContentContainer from '../layout/ContentContainer';
import BrandsForm from './BrandsForm';

function BulkEditBrands() {
  return (
    <ContentContainer heading="Bulk Edit Brands">
      <Button
        as={RouterLink}
        to="/brand"
        variant="ghost"
        colorScheme="blue"
        aria-label="Back"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        Back
      </Button>
      <BrandsForm />
    </ContentContainer>
  );
}

export default BulkEditBrands;
