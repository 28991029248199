import { InputGroup, Input, IconButton, ButtonGroup, InputLeftAddon, useClipboard } from '@chakra-ui/react';
import { faArrowsRotate, faCheck, faCopy, faEye, faEyeSlash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import generatePassword from './generatePassword';

interface IProps {
  name: string;
  placeholder?: string;
}

function PasswordInput({ name, placeholder }: IProps) {
  const { register, setValue } = useFormContext();
  const { onBlur, name: fieldName, ref } = register(name);
  const [password, setPassword] = useState('');
  const { hasCopied, onCopy } = useClipboard(password);
  const [show, setShow] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
    setValue(name, value);
  };

  const toggleShow = () => setShow(!show);

  const generate = () => {
    const generatedPassword = generatePassword(16);
    setPassword(generatedPassword);
    setValue(name, generatedPassword);
  };

  return (
    <InputGroup size="md">
      <InputGroup>
        <InputLeftAddon p="0">
          <ButtonGroup spacing="0" variant="ghost">
            <IconButton
              aria-label={show ? 'Hide' : 'Show'}
              icon={<FontAwesomeIcon icon={show ? faEyeSlash : faEye} />}
              borderRadius="0"
              onClick={toggleShow}
            />
            <IconButton
              aria-label="Generate"
              icon={<FontAwesomeIcon icon={faArrowsRotate} />}
              borderRadius="0"
              onClick={generate}
            />
            <IconButton
              aria-label="Copy"
              borderRadius="0"
              icon={<FontAwesomeIcon icon={hasCopied ? faCheck : faCopy} onClick={onCopy} />}
            />
          </ButtonGroup>
        </InputLeftAddon>
        <Input
          type={show ? 'text' : 'password'}
          onChange={onChange}
          onBlur={onBlur}
          name={fieldName}
          value={password}
          autoComplete="off"
          placeholder={placeholder}
        />
      </InputGroup>
    </InputGroup>
  );
}

PasswordInput.defaultProps = {
  placeholder: 'Password',
};

export default PasswordInput;
