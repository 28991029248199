import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ButtonGroup,
  Button,
  SimpleGrid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Switch,
  useColorMode,
  useDisclosure,
  useToast,
  Text,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Badge,
  Link,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import ContentContainer from '../layout/ContentContainer';
import { DeploymentEnvironment, IDeploymentRequest } from './types';
import validationSchema from './validationSchema';
import { useCreateDeploymentMutation } from './deploymentApi';
import { useLoginQuery } from '../auth/authApi';
import { useGetWebsitesQuery } from '../website/websiteApi';
import capitalize from '../../helpers/capitalize';

function Deployments() {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: currentUser } = useLoginQuery();
  const permissions = currentUser ? currentUser.authenticatedUser.permissions : [];
  const canDeployDev = permissions.includes('development_deployments');
  const canDeployStaging = permissions.includes('staging_deployments');
  const canDeployProd = permissions.includes('production_deployments');
  const environments: DeploymentEnvironment[] = [];
  const [createDeployment, { isLoading, reset: resetData }] = useCreateDeploymentMutation();
  const { data: websites, isLoading: isLoadingWebsites } = useGetWebsitesQuery();

  if (canDeployDev) {
    environments.push('development');
  }

  if (canDeployStaging) {
    environments.push('staging');
  }

  if (canDeployProd) {
    environments.push('production');
  }

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    reset,
    watch,
  } = useForm<IDeploymentRequest>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      environment: '',
      domain: '',
      branch: '',
      db_patch: '',
      apply_previous_db_patches: false,
      skip_backup: false,
    },
  });

  const watchEnvironment = watch('environment');

  const onReset = () => {
    onClose();
    reset();
    resetData();
  };

  const onSubmit = async (values: IDeploymentRequest) => {
    try {
      const { environment, domain } = await createDeployment(values).unwrap();
      const envName = capitalize(environment);
      let message: React.ReactNode = `${envName} deployment started! You will receive an email when it completes.`;

      if (environment === 'development' && domain) {
        message = (
          <Text>
            {message}{' '}
            <Link href={`https://${domain}`} isExternal textDecoration="underline">
              View Website
            </Link>
          </Text>
        );
      } else if (environment === 'staging') {
        message = (
          <Text>
            {message}{' '}
            <Link href="https://staging.aftermarketwebsites.com" isExternal textDecoration="underline">
              View Website
            </Link>
          </Text>
        );
      }

      toast({
        title: message,
        status: 'success',
        isClosable: true,
        position: 'top',
      });

      onClose();
      reset();
    } catch (error) {
      onClose();
      resetData();

      toast({
        title: `An internal server error occurred.`,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <ContentContainer heading="Deployments">
      <form>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <FormControl isRequired isInvalid={!!errors.environment}>
            <FormLabel htmlFor="environment">Environment</FormLabel>
            <Select
              id="environment"
              placeholder="Select environment"
              {...register('environment')}
              defaultValue={environments.length === 1 ? environments[0] : undefined}
            >
              {environments.map((env) => (
                <option value={env} key={env}>
                  {capitalize(env)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.environment && errors.environment.message}</FormErrorMessage>
          </FormControl>
          {watchEnvironment === 'development' && (
            <FormControl isRequired={watchEnvironment === 'development'} isInvalid={!!errors.domain}>
              <FormLabel htmlFor="domain">Dev Site</FormLabel>
              <Select id="domain" placeholder="Select dev site" {...register('domain')} disabled={isLoadingWebsites}>
                {websites !== undefined &&
                  websites
                    .filter(({ is_dev_site }) => is_dev_site)
                    .map(({ domain }) => (
                      <option value={domain} key={domain}>
                        {domain}
                      </option>
                    ))}
              </Select>
              <FormErrorMessage>{errors.domain && errors.domain.message}</FormErrorMessage>
            </FormControl>
          )}
          <FormControl isRequired isInvalid={!!errors.branch}>
            <FormLabel htmlFor="branch">Branch Name</FormLabel>
            <Input id="branch" placeholder="Enter branch name" {...register('branch')} />
            <FormErrorMessage>{errors.branch && errors.branch.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.db_patch}>
            <FormLabel htmlFor="db_patch">Database Patch Name</FormLabel>
            <Input id="db_patch" placeholder="Enter database patch name" {...register('db_patch')} />
            <FormErrorMessage>{errors.db_patch && errors.db_patch.message}</FormErrorMessage>
          </FormControl>
          {watchEnvironment === 'development' && (
            <FormControl display="flex" alignItems="center">
              <Switch id="apply_previous_db_patches" {...register('apply_previous_db_patches')} />
              <FormLabel htmlFor="apply_previous_db_patches" mb="0" ml="3">
                Apply previous database patches?
              </FormLabel>
            </FormControl>
          )}
          {watchEnvironment === 'production' && (
            <FormControl display="flex" alignItems="center">
              <Switch id="skip_backup" {...register('skip_backup')} />
              <FormLabel htmlFor="skip_backup" mb="0" ml="3">
                Skip database backup?
              </FormLabel>
              <Tooltip
                label="Only skip the database backup if this deployment will not run a database patch. Skipping the database backup allows the deployment to complete faster."
                rounded="md"
                placement="right-start"
                hasArrow
              >
                <Text display="inline-block" color={colorMode === 'dark' ? 'blue.200' : 'blue.600'}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Text>
              </Tooltip>
            </FormControl>
          )}
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button
            type="button"
            colorScheme="green"
            isLoading={isLoading || isSubmitting}
            disabled={!isValid}
            onClick={onOpen}
          >
            Submit
          </Button>
          <Button type="button" colorScheme="gray" disabled={isLoading || isSubmitting} onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
      </form>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount
        closeOnOverlayClick={false}
        size="lg"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deployment</ModalHeader>
          <ModalCloseButton tabIndex={0} />
          <ModalBody pb={6}>
            {watchEnvironment.length > 0 && (
              <Text>
                Are you sure you want to start a{' '}
                <Badge variant="solid" colorScheme="orange">
                  {watchEnvironment}
                </Badge>{' '}
                deployment?
              </Text>
            )}
            {!watchEnvironment && <Text>You must provide a valid deployment.</Text>}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={3}>
              <Button type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
                colorScheme="red"
                isLoading={isLoading || isSubmitting}
              >
                Start Deployment
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ContentContainer>
  );
}

export default Deployments;
