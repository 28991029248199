import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IBrandFilterFormValues } from './types';
import BrandFilterForm from './BrandFilterForm';
import { useCreateBrandFilterMutation } from './brandApi';

function AddBrandFilter() {
  const [createBrandFilter] = useCreateBrandFilterMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (values: IBrandFilterFormValues) => {
    try {
      const res = await createBrandFilter(values).unwrap();
      const success = !!res.id;

      toast({
        title: success ? 'Successfully created the custom filter!' : 'Failed to create the custom filter!',
        status: success ? 'success' : 'error',
        isClosable: true,
        position: 'top',
      });

      if (success) {
        navigate(`/brand/filter/${res.id}`);
      }
    } catch (error) {
      toast({
        title: 'Failed to create the custom filter!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return <BrandFilterForm heading="Add Custom Filter" onSubmit={onSubmit} />;
}

export default AddBrandFilter;
