import { SimpleGrid, ButtonGroup, Button, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import ContentContainer from '../../layout/ContentContainer';
import AllScriptsButton from '../AllScriptsButton';
import { useBrandUsageMutation } from '../scriptApi';
import ScriptResults from '../ScriptResults';
import MultiSelectList from '../../multiSelectList/MultiSelectList';
import { useGetAllBrandsQuery } from '../../brand/brandApi';
import AccordionGroupResults from '../AccordionGroupResults';
import { IUsageGroupRequest } from '../types';
import usageGroupValidation from '../validationSchema';
import useMap from '../../../hooks/useMap';

function BrandUsage() {
  const toast = useToast();
  const { data: brands } = useGetAllBrandsQuery();
  const [brandUsage, { data, isUninitialized, isLoading, isSuccess, isError, reset: resetData }] =
    useBrandUsageMutation();
  const { items, addItem, removeItem, clearItems } = useMap();

  const {
    handleSubmit,
    reset: resetForm,
    setValue,
  } = useForm<IUsageGroupRequest>({
    resolver: yupResolver(usageGroupValidation),
  });

  useEffect(() => {
    setValue(
      'items',
      Array.from(items[Symbol.iterator]()).map(([key, value]) => ({ code: key, name: value })),
    );
  }, [items, setValue]);

  const onSubmit = async (values: IUsageGroupRequest) => {
    try {
      await brandUsage(values);
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const onReset = () => {
    resetForm();
    resetData();
    clearItems();
  };

  return (
    <ContentContainer heading="Brand Usage">
      <AllScriptsButton />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <MultiSelectList
            searchable
            allowSelectAll={false}
            leftHeading="Select Brands"
            rightHeading="Selected Brands"
            data={
              brands
                ? brands.map(({ aaia_brand_code, name }) => ({
                    key: aaia_brand_code,
                    value: `${name} (${aaia_brand_code})`,
                  }))
                : []
            }
            items={items}
            addItem={addItem}
            removeItem={removeItem}
            clearItems={clearItems}
          />
        </SimpleGrid>
        <ButtonGroup mt="6" spacing="3">
          <Button colorScheme="green" isLoading={isLoading} type="submit">
            Submit
          </Button>
          <Button colorScheme="gray" disabled={isLoading} type="button" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <ScriptResults isUninitialized={isUninitialized} isLoading={isLoading} isSuccess={isSuccess} isError={isError}>
          {isSuccess && data && <AccordionGroupResults results={data} />}
        </ScriptResults>
      </form>
    </ContentContainer>
  );
}

export default BrandUsage;
