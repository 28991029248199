import format from 'date-fns/format';

export default (datetime: string | null, formatAs = 'MM/dd/yy hh:mm:ss aaa') => {
  if (typeof datetime !== 'string') {
    return 'N/A';
  }

  // Convert from UTC to New York time
  const dt = new Date(datetime);
  const offset = dt.getTimezoneOffset();
  dt.setMinutes(dt.getMinutes() - offset);

  return format(dt, formatAs);
};
