import * as yup from 'yup';

export default yup.object().shape({
  environment: yup
    .string()
    .oneOf(
      ['development', 'staging', 'production'],
      'Environment must be one of the following: Development, Staging, Production',
    ),
  domain: yup.string().when('environment', {
    is: 'development',
    then: (schema) => schema.required('You must select a dev site for this deployment.'),
  }),
  branch: yup.string().required('Branch Name is required.'),
  db_patch: yup.string(),
  apply_previous_db_patches: yup.boolean(),
  skip_backup: yup.boolean(),
});
