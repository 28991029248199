import { Text, TextProps } from '@chakra-ui/react';

function PopoverText({ children, color }: TextProps) {
  return (
    <Text whiteSpace="normal" color={color}>
      {children}
    </Text>
  );
}

export default PopoverText;
