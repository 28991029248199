import { Center, Text, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import { IWebsite } from './types';

interface IProps {
  results: IWebsite[];
}

function WebsiteResults({ results }: IProps) {
  if (!results.length) {
    return (
      <Center>
        <Text>No results</Text>
      </Center>
    );
  }

  return (
    <TableContainer>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Domain</Th>
            <Th>Database</Th>
          </Tr>
        </Thead>
        <Tbody>
          {results.map(({ database_name, domain }, i) => (
            <Tr key={domain}>
              <Td>{i + 1}</Td>
              <Td>{domain}</Td>
              <Td>{database_name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default WebsiteResults;
