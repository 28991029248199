import { Box, Text, useColorMode } from '@chakra-ui/react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import formatCurrency from '../../helpers/formatCurrency';

function OrderChartTooltip({ active, payload }: TooltipProps<ValueType, NameType>) {
  const { colorMode } = useColorMode();
  const bg = colorMode === 'dark' ? 'gray.700' : 'gray.200';
  const textColor = colorMode === 'dark' ? 'gray.200' : 'gray.700';

  if (active && payload && payload.length) {
    const { name, count, total } = payload[0].payload;

    return (
      <Box p="3" bg={bg} textColor={textColor} borderRadius="md" boxShadow="md">
        <Text>{name}</Text>
        <Text>{count} orders</Text>
        <Text>{formatCurrency(total)}</Text>
      </Box>
    );
  }

  return null;
}

export default OrderChartTooltip;
