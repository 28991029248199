import * as yup from 'yup';

export const websiteValidationSchema = yup.object().shape({
  domain: yup
    .string()
    .min(2, 'Domain must contain at least 2 characters.')
    .max(200, 'Domain cannot contain more than 200 characters.')
    .required(),
  server_id: yup.number().integer().required(),
  plan: yup.string().oneOf(['regular', 'pro', 'brand'], 'Plan must be one of the following: Regular, Pro, Brand'),
  seller_type: yup
    .string()
    .oneOf(
      ['brand', 'dealer', 'development', 'distributor'],
      'Site Type must be one of the following: Dealer, Distributor',
    ),
  currency: yup.string().oneOf(['USD', 'CAD'], 'Currency must be one of the following: USD, CAD'),
  country: yup.string().oneOf(['US', 'CA'], 'Country must be one of the following: US, CA'),
  is_dev_site: yup.boolean(),
  create_dns_record: yup.boolean(),
  secure_www: yup.boolean(),
  db_password: yup.string(),
  ftp_password: yup.string(),
});

export const changeDomainValidationSchema = yup.object().shape({
  domain: yup
    .string()
    .min(2, 'Domain must contain at least 2 characters.')
    .max(200, 'Domain cannot contain more than 200 characters.')
    .required(),
  domain_alias: yup.lazy((value) => {
    if (!value) {
      return yup.string().notRequired();
    }

    return yup
      .string()
      .min(2, 'Domain alias must contain at least 2 characters.')
      .max(200, 'Domain alias cannot contain more than 200 characters.');
  }),
  secure_www: yup.boolean(),
});
