/* eslint-disable react/no-unstable-nested-components */
import {
  Center,
  Flex,
  Button,
  Tooltip,
  IconButton,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Link,
  Badge,
  Text,
  Spacer,
  FormControl,
  FormLabel,
  Switch,
  Box,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons';
import CustomButtonGroup from '../buttonGroup/CustomButtonGroup';
import ContentContainer from '../layout/ContentContainer';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';
import DataTable from '../table/DataTable';
import { IGlobalInstantRebate } from './types';
import { useGetGlobalInstantRebatesQuery, useDeleteGlobalInstantRebateMutation } from './globalInstantRebateApi';
import formatDate from '../../helpers/formatDate';
import formatDatetime from '../../helpers/formatDatetime';
import ConfirmDeleteModal from '../confirmDeleteModal/ConfirmDeleteModal';

function GlobalInstantRebates() {
  const toast = useToast();
  const [showExpired, setShowExpired] = useState(false);
  const { data, isLoading } = useGetGlobalInstantRebatesQuery({ show_expired: showExpired });
  const [deleteGlobalInstantRebate] = useDeleteGlobalInstantRebateMutation();

  const columns = useMemo<ColumnDef<IGlobalInstantRebate>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => <Text style={{ maxWidth: '250px' }}>{row.original.name}</Text>,
      },
      {
        accessorKey: 'type',
        header: 'Discount',
        cell: ({ row }) => {
          const discount = Number(row.original.discount).toFixed(2);
          return row.original.type === 'F' ? `$${discount}` : `${discount}%`;
        },
      },
      {
        accessorKey: 'num_websites',
        header: 'Websites Using',
        cell: ({ row }) => {
          if (row.original.num_websites === 0) {
            return (
              <Button variant="ghost" colorScheme="blue" disabled>
                {row.original.num_websites}
              </Button>
            );
          }

          return (
            <Popover>
              <PopoverTrigger>
                <Button variant="ghost" colorScheme="blue">
                  {row.original.num_websites}
                </Button>
              </PopoverTrigger>
              <PopoverContent textAlign="left">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Enabled on these websites:</PopoverHeader>
                <PopoverBody>
                  {row.original.websites.map((website) => (
                    <Link href={`https://${website}`} isExternal key={website} display="block">
                      {website}
                    </Link>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          );
        },
        meta: {
          align: 'right',
        },
      },
      {
        accessorKey: 'date_start',
        header: 'Date',
        cell: ({ row }) => (
          <Flex>
            <Text>
              {formatDate(row.original.date_start)} - {formatDate(row.original.date_end)}
            </Text>
            <Spacer />
            {row.original.is_expired ? <Badge colorScheme="red">Expired</Badge> : null}
          </Flex>
        ),
      },
      {
        accessorKey: 'date_added',
        header: 'Created',
        cell: ({ row }) => formatDatetime(row.original.date_added),
      },
      {
        header: 'Action',
        id: 'action',
        meta: {
          isNumeric: true,
        },
        cell: ({ row }) => {
          const { uuid } = row.original;
          const { name } = row.original;

          const deleteAction = async () => {
            const res = await deleteGlobalInstantRebate(uuid).unwrap();

            if (res.success) {
              toast({
                title: `Successfully deleted ${name}!`,
                status: 'success',
                isClosable: true,
                position: 'top',
              });
            } else {
              toast({
                title: `Failed to delete ${name}!`,
                status: 'error',
                isClosable: true,
                position: 'top',
              });
            }
          };

          return (
            <>
              <Tooltip label="Edit Global Instant Rebate" placement="left" hasArrow>
                <IconButton
                  as={RouterLink}
                  to={`/global-instant-rebate/${uuid}`}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="Edit Global Instant Rebate"
                  icon={<FontAwesomeIcon icon={faPenToSquare} />}
                  data-testid={`edit-global-instant-rebate-${uuid}`}
                />
              </Tooltip>
              <ConfirmDeleteModal
                iconButton
                buttonLabel="Delete Global Instant Rebate"
                modalHeading={`Delete ${name}?`}
                deleteAction={deleteAction}
                testId={`delete-global-instant-rebate-${uuid}`}
              />
            </>
          );
        },
      },
    ],
    [deleteGlobalInstantRebate, toast],
  );

  return (
    <ContentContainer heading="Global Instant Rebates">
      <Flex>
        <CustomButtonGroup mb="6">
          <Button as={RouterLink} to="/global-instant-rebate/add" colorScheme="green">
            Add Global Instant Rebate
          </Button>
        </CustomButtonGroup>
        <Spacer />
        <Box>
          <FormControl display="flex" alignItems="center" my="2">
            <FormLabel htmlFor="showExpired" mb="0">
              Show Expired
            </FormLabel>
            <Switch id="showExpired" onChange={(e) => setShowExpired(e.target.checked)} />
          </FormControl>
        </Box>
      </Flex>
      {isLoading && (
        <Center>
          <ThemedSyncLoader />
        </Center>
      )}
      {data && <DataTable columns={columns} data={data} />}
    </ContentContainer>
  );
}

export default GlobalInstantRebates;
