import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import NotFound from './features/notFound/NotFound';
import Home from './features/home/Home';
import Layout from './features/layout/Layout';
import Providers from './Providers';
import appRoutes from './app/routes';

function App() {
  return (
    <Providers>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            {appRoutes.map(({ path, Component, routes }) => {
              const nestedRoutes =
                routes && Array.isArray(routes)
                  ? routes.map((nested) => {
                      const nestedPath = nested.path;
                      const NestedComponent = nested.Component;
                      return <Route key={nestedPath} path={nestedPath} element={<NestedComponent />} />;
                    })
                  : null;

              return (
                <Route path={path} key={path}>
                  <Route index element={<Component />} />
                  {nestedRoutes}
                </Route>
              );
            })}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Providers>
  );
}

export default App;
