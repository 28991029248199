import { Link, Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import capitalize from '../../helpers/capitalize';
import friendlyDatetime from '../../helpers/friendlyDatetime';
import { useGetRecentDeploymentsQuery } from './statApi';

function RecentDeployments() {
  const { data, isFetching } = useGetRecentDeploymentsQuery();

  return (
    <Skeleton isLoaded={!isFetching}>
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Domain</Th>
              <Th>Branch</Th>
              <Th>Status</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data &&
              data.map(({ id, environment, branch, status, domain, created_at }) => {
                let statusText = 'Queued';

                if (status === 2) {
                  statusText = 'Complete';
                } else if (status === 1) {
                  statusText = 'Running';
                }

                let domainText = '';

                if (environment === 'staging') {
                  domainText = 'staging.aftermarketwebsites.com';
                } else if (environment === 'production') {
                  domainText = 'All';
                } else if (environment === 'development' && domain) {
                  domainText = domain;
                }

                return (
                  <Tr key={id}>
                    <Td>{capitalize(environment)}</Td>
                    <Td>
                      {domainText !== 'All' ? (
                        <Link href={`https://${domainText}`} isExternal>
                          {domainText}
                        </Link>
                      ) : (
                        domainText
                      )}
                    </Td>
                    <Td>{branch}</Td>
                    <Td>{statusText}</Td>
                    <Td>{friendlyDatetime(created_at)}</Td>
                  </Tr>
                );
              })}
            {!isFetching && data !== undefined && !data.length && (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  No deployments have been made yet.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Skeleton>
  );
}

export default RecentDeployments;
