import format from 'date-fns/format';

export default (date: string | null, formatAs = 'MM/dd/yy') => {
  if (typeof date !== 'string') {
    return 'N/A';
  }

  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000); // remove timezone offset

  return format(dtDateOnly, formatAs);
};
