import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  ButtonGroup,
  FormErrorMessage,
  FormHelperText,
  ButtonProps,
} from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

interface IProps extends ButtonProps {
  iconButton?: boolean;
  buttonLabel: string;
  modalHeading: string;
  deleteAction: () => void;
  testId?: string | undefined;
}

interface IFormValues {
  confirmDelete: string;
}

function ConfirmDeleteModal(props: IProps) {
  const { iconButton = false, buttonLabel, modalHeading, deleteAction, testId = undefined, ...buttonProps } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setFocus,
    reset,
  } = useForm<IFormValues>();

  const { onChange, onBlur, ref, name } = register('confirmDelete');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const resetAndClose = () => {
    reset();
    onClose();
  };

  const onSubmit = ({ confirmDelete }: IFormValues) => {
    if (confirmDelete !== 'DELETE') {
      setError('confirmDelete', {
        type: 'custom',
        message: 'Value must be DELETE.',
      });

      setFocus('confirmDelete');
    } else {
      deleteAction();
      resetAndClose();
    }
  };

  const button = iconButton ? (
    <Tooltip label={buttonLabel} placement="left" hasArrow>
      <IconButton
        variant="ghost"
        colorScheme="red"
        aria-label={buttonLabel}
        icon={<FontAwesomeIcon icon={faTrashCan} />}
        onClick={onOpen}
        ref={finalRef}
        data-testid={testId}
        {...buttonProps}
      />
    </Tooltip>
  ) : (
    <Button colorScheme="red" onClick={onOpen} ref={finalRef} {...buttonProps}>
      {buttonLabel}
    </Button>
  );

  return (
    <>
      {button}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount
        closeOnOverlayClick={false}
        size="lg"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeading}</ModalHeader>
          <ModalCloseButton tabIndex={0} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pb={6}>
              <FormControl isInvalid={!!errors.confirmDelete}>
                <FormLabel htmlFor="confirmDelete">Type DELETE to confirm</FormLabel>
                <Input
                  id="confirmDelete"
                  placeholder="DELETE"
                  tabIndex={-1}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  name={name}
                />
                <FormHelperText>Case-sensitive</FormHelperText>
                <FormErrorMessage>{errors.confirmDelete && errors.confirmDelete.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup spacing={3}>
                <Button onClick={resetAndClose}>Cancel</Button>
                <Button type="submit" colorScheme="red" isLoading={isSubmitting}>
                  Confirm
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

ConfirmDeleteModal.defaultProps = {
  iconButton: false,
  testId: undefined,
};

export default ConfirmDeleteModal;
