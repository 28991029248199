import { ButtonGroup, Button, Alert, AlertIcon, useToast } from '@chakra-ui/react';
import ContentContainer from '../../layout/ContentContainer';
import AllScriptsButton from '../AllScriptsButton';
import { useCreateDemoSnapshotMutation } from '../scriptApi';
import ScriptResults from '../ScriptResults';

function CreateDemoSnapshot() {
  const toast = useToast();
  const [createDemoSnapshot, { data, isUninitialized, isLoading, isSuccess, isError }] =
    useCreateDemoSnapshotMutation();

  const onClick = async () => {
    try {
      await createDemoSnapshot();
    } catch (error) {
      toast({
        title: 'An unexpected error occurred!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <ContentContainer heading="Create Demo Snapshot">
      <AllScriptsButton />
      <Alert status="info" rounded="md">
        <AlertIcon />
        New backups will be created for the demo sites (demo.aftermarketwebsites.com &amp; ymm.aftermarketwebsites.com).
      </Alert>
      <ButtonGroup mt="6" spacing="3">
        <Button colorScheme="green" isLoading={isLoading} onClick={onClick} type="submit">
          Start Snapshot
        </Button>
      </ButtonGroup>
      <ScriptResults isUninitialized={isUninitialized} isLoading={isLoading} isSuccess={isSuccess} isError={isError}>
        {isSuccess && data !== undefined && <pre>{data.output}</pre>}
      </ScriptResults>
    </ContentContainer>
  );
}

export default CreateDemoSnapshot;
