import {
  Text,
  Box,
  Link,
  Flex,
  Button,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  LinkOverlay,
  LinkBox,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { IBrandFile } from '../types';

interface IProps extends IBrandFile {
  onDelete: (file: string) => Promise<void>;
}

function BrandFile({ name, downloadUrl, timestamp, size, onDelete }: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let info = '';

  if (size && timestamp) {
    info = ` (${size}, ${timestamp})`;
  } else if (size) {
    info = ` (${size})`;
  } else if (timestamp) {
    info = ` (${timestamp})`;
  }

  const confirmDelete = async () => {
    await onDelete(name);
    onClose();
  };

  return (
    <Flex display="flex" gap="2" alignItems="center" my="3">
      <Box>
        <Link href={downloadUrl} isExternal color="blue.400">
          <Text fontWeight="bold">{name}</Text>
        </Link>
      </Box>
      {info && (
        <Box>
          <Text fontSize="sm" color="gray.500">
            {info}
          </Text>
        </Box>
      )}
      <Tooltip label="Download" aria-label="Download" hasArrow>
        <LinkBox>
          <LinkOverlay href={downloadUrl} isExternal>
            <Button size="xs" colorScheme="blue">
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </LinkOverlay>
        </LinkBox>
      </Tooltip>
      <Tooltip label="Delete" aria-label="Delete" hasArrow>
        <Box>
          <Button size="xs" colorScheme="red" onClick={onOpen}>
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </Box>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete {name}?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete {name}?</ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={3}>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={confirmDelete} colorScheme="red">
                Confirm
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default BrandFile;
