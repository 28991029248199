import { Flex, Box, Text, useColorMode, LinkBox, LinkOverlay, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ContentContainer from '../layout/ContentContainer';

interface IScriptLink {
  to: string;
  label: string;
  description?: string;
}

function Scripts() {
  const { colorMode } = useColorMode();

  const links: IScriptLink[] = [
    {
      to: '/script/setting-usage',
      label: 'Setting Usage',
      description: 'View a list of sites that have a given setting enabled.',
    },
    {
      to: '/script/brand-usage',
      label: 'Brand Usage',
      description: 'View a list of sites that have a given brand installed.',
    },
    {
      to: '/script/delete-brands',
      label: 'Delete Brands',
      description: 'Delete certain brands from all selected sites.',
    },
    {
      to: '/script/payment-gateway-usage',
      label: 'Payment Gateway Usage',
      description: 'View a list of sites that have certain payment gateways installed and enabled.',
    },
    {
      to: '/script/shipping-gateway-usage',
      label: 'Shipping Gateway Usage',
      description: 'View a list of sites that have certain shipping gateways installed and enabled.',
    },
    {
      to: '/script/integration-usage',
      label: 'Integration Usage',
      description: 'View a list of sites that have certain integrations installed and enabled.',
    },
    {
      to: '/script/custom-brand-page-usage',
      label: 'Custom Brand Page Usage',
      description: 'View a list of sites that have certain custom brand pages installed.',
    },
    {
      to: '/script/user-logins',
      label: 'User Logins',
      description: 'View the number of non-AW users that have logged in to a site in the past month.',
    },
    {
      to: '/script/generate-brand-file',
      label: 'Generate Brand File',
      description: 'Generate data files for given brands to be used by the product upload tool.',
    },
    {
      to: '/script/create-demo-snapshot',
      label: 'Create Demo Snapshot',
      description:
        'Create new snapshots (i.e. backups) of the current state of demo.aftermarketwebsites.com and ymm.aftermarketwebsites.com.',
    },
  ];

  return (
    <ContentContainer heading="Scripts">
      <Flex direction="column" gap={3} mb={3}>
        {links.map(({ to, label, description }) => (
          <LinkBox
            key={to}
            bg={colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.100'}
            py={3}
            px={6}
            gap={3}
            alignItems="center"
            borderRadius="lg"
            borderLeftWidth={4}
            borderLeftStyle="solid"
            borderLeftColor={colorMode === 'dark' ? 'purple.600' : 'purple.300'}
          >
            <Heading size="md">
              <LinkOverlay as={Link} to={to}>
                {label}
              </LinkOverlay>
            </Heading>
            {description && (
              <Text mt={2} fontSize="sm" as="em" color={colorMode === 'dark' ? 'gray.400' : 'gray.700'}>
                {description}
              </Text>
            )}
          </LinkBox>
        ))}
      </Flex>
    </ContentContainer>
  );
}

export default Scripts;
