import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import { IGlobalInstantRebate, ICategory, ISeries, IPart, ISeriesRequest, IPartsRequest } from './types';
import { IBrand } from '../brand/types';

interface IDeleteResponse {
  success: boolean;
  id: number;
}

interface IGlobalInstantRebatesRequest {
  show_expired?: boolean;
}

export const globalInstantRebateApi = createApi({
  reducerPath: 'globalInstantRebateApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['BrandList', 'CategoryList', 'SeriesList', 'PartList', 'GlobalInstantRebates'],
  endpoints: (builder) => ({
    getAllBrands: builder.query<IBrand[], void>({
      query: () => ({
        url: 'global-instant-rebate/brands',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'BrandList', id } as const)), { type: 'BrandList', id: 'LIST' }]
          : [{ type: 'BrandList', id: 'LIST' }],
    }),
    getAllCategories: builder.query<ICategory[], void>({
      query: () => ({
        url: 'global-instant-rebate/categories',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ name }) => ({ type: 'CategoryList', id: name } as const)),
              { type: 'CategoryList', id: 'LIST' },
            ]
          : [{ type: 'CategoryList', id: 'LIST' }],
    }),
    getAllSeries: builder.query<ISeries[], ISeriesRequest>({
      query: (data) => ({
        url: `global-instant-rebate/series`,
        method: 'POST',
        data,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ aaia, series }) => ({ type: 'SeriesList', id: `${aaia}~${series}` } as const)),
              { type: 'SeriesList', id: 'LIST' },
            ]
          : [{ type: 'SeriesList', id: 'LIST' }],
    }),
    getParts: builder.query<IPart[], IPartsRequest>({
      query: (data) => ({
        url: 'global-instant-rebate/parts',
        method: 'POST',
        data,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ aaia, part }) => ({ type: 'PartList', id: `${aaia}~${part}` } as const)),
              { type: 'PartList', id: 'LIST' },
            ]
          : [{ type: 'PartList', id: 'LIST' }],
    }),
    getGlobalInstantRebates: builder.query<IGlobalInstantRebate[], IGlobalInstantRebatesRequest>({
      query: (params) => ({
        url: 'global-instant-rebate',
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid }) => ({ type: 'GlobalInstantRebates', id: uuid } as const)),
              { type: 'GlobalInstantRebates', id: 'LIST' },
            ]
          : [{ type: 'GlobalInstantRebates', id: 'LIST' }],
    }),
    getGlobalInstantRebate: builder.query<IGlobalInstantRebate, string>({
      query: (uuid) => ({
        url: `global-instant-rebate/${uuid}`,
        method: 'GET',
      }),
      providesTags: (result, error, uuid) => [{ type: 'GlobalInstantRebates', id: uuid }],
    }),
    createGlobalInstantRebate: builder.mutation<IGlobalInstantRebate, Partial<IGlobalInstantRebate>>({
      query: (data) => ({
        url: 'global-instant-rebate',
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'GlobalInstantRebates', id: 'LIST' }],
    }),
    updateGlobalInstantRebate: builder.mutation<IGlobalInstantRebate, Partial<IGlobalInstantRebate>>({
      query: (data) => {
        const { uuid, ...body } = data;
        return {
          url: `global-instant-rebate/${uuid}`,
          method: 'PUT',
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [{ type: 'GlobalInstantRebates', id: uuid }],
    }),
    deleteGlobalInstantRebate: builder.mutation<IDeleteResponse, string>({
      query: (uuid) => ({
        url: `global-instant-rebate/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, uuid) => [{ type: 'GlobalInstantRebates', id: uuid }],
    }),
  }),
});

export const {
  useGetAllBrandsQuery,
  useGetAllCategoriesQuery,
  useGetAllSeriesQuery,
  useGetPartsQuery,
  useGetGlobalInstantRebatesQuery,
  useGetGlobalInstantRebateQuery,
  useCreateGlobalInstantRebateMutation,
  useUpdateGlobalInstantRebateMutation,
  useDeleteGlobalInstantRebateMutation,
} = globalInstantRebateApi;
