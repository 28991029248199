import * as yup from 'yup';

export default yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      code: yup.string(),
      name: yup.string(),
    }),
  ),
});
