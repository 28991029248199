import { useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { IBrandFilterFormValues } from './types';
import BrandFilterForm from './BrandFilterForm';
import { useGetBrandFilterQuery, useUpdateBrandFilterMutation } from './brandApi';

function EditBrandFilter() {
  const { id } = useParams();
  const { data: brandFilter } = useGetBrandFilterQuery(id ?? skipToken);
  const [updateBrandFilter] = useUpdateBrandFilterMutation();
  const toast = useToast();
  const fullName = brandFilter ? brandFilter.name : '';
  const heading = `Edit Custom Filter${fullName ? `: ${fullName}` : ''}`;

  const onSubmit = async (values: IBrandFilterFormValues) => {
    try {
      const res = await updateBrandFilter({ ...brandFilter, ...values }).unwrap();
      const success = !!res.id;

      toast({
        title: success ? 'Successfully updated the custom filter!' : 'Failed to update the custom filter!',
        status: success ? 'success' : 'error',
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      toast({
        title: 'Failed to update the custom filter!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return <BrandFilterForm heading={heading} onSubmit={onSubmit} brandFilter={brandFilter} />;
}

export default EditBrandFilter;
