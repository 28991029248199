import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import { useLoginQuery } from '../auth/authApi';
import ContentContainer from '../layout/ContentContainer';
import Card from '../stat/Card';
import Stat from '../stat/Stat';
import { useGetTotalsQuery } from '../stat/statApi';
import 'react-datepicker/dist/react-datepicker.css';
import RecentDeployments from '../stat/RecentDeployments';
import Orders from '../stat/Orders';
import MostProducts from '../stat/MostProducts';

function Home() {
  const { data: currentUser } = useLoginQuery();
  const { data: totals, isFetching: isFetchingTotals } = useGetTotalsQuery();

  return (
    <ContentContainer heading={`Welcome, ${currentUser?.authenticatedUser.first_name}`} fullWidth>
      <Grid
        templateRows={{ base: 'repeat(7, auto)', lg: 'repeat(3, auto)' }}
        templateColumns={{ base: '1fr', lg: 'repeat(4, 1fr)' }}
        gap="6"
      >
        <GridItem>
          <Card>
            <Skeleton isLoaded={!isFetchingTotals}>
              <Stat isLoading={isFetchingTotals} value={totals?.totalActiveWebsites} text="Active Websites" />
            </Skeleton>
          </Card>
        </GridItem>
        <GridItem>
          <Card>
            <Skeleton isLoaded={!isFetchingTotals}>
              <Stat isLoading={isFetchingTotals} value={totals?.totalSupportingBrands} text="Supporting Brands" />
            </Skeleton>
          </Card>
        </GridItem>
        <GridItem>
          <Card>
            <Skeleton isLoaded={!isFetchingTotals}>
              <Stat
                isLoading={isFetchingTotals}
                value={totals?.totalNonSupportingBrands}
                text="Non-Supporting Brands"
              />
            </Skeleton>
          </Card>
        </GridItem>
        <GridItem>
          <Card>
            <Skeleton isLoaded={!isFetchingTotals}>
              <Stat isLoading={isFetchingTotals} value={totals?.totalProducts} text="Total Products" />
            </Skeleton>
          </Card>
        </GridItem>
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          <Card heading="Top 5 Most Products">
            <MostProducts />
          </Card>
        </GridItem>
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          <Card heading="Recent Deployments">
            <RecentDeployments />
          </Card>
        </GridItem>
        <GridItem colSpan={{ base: 1, lg: 4 }}>
          <Card heading="Orders">
            <Orders />
          </Card>
        </GridItem>
      </Grid>
    </ContentContainer>
  );
}

export default Home;
