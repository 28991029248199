import { Box, Container, Flex, Link, Text, useColorMode } from '@chakra-ui/react';
import { faHouseBlank } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { permissions } from '../../app/permissions';
import { useLoginQuery } from '../auth/authApi';
import PageHeading from '../heading/PageHeading';

interface IProps {
  heading: React.ReactNode;
  children?: React.ReactNode;
  fullWidth?: boolean;
}

function ContentContainer({ heading, children, fullWidth }: IProps) {
  const { colorMode } = useColorMode();
  const { data: currentUser } = useLoginQuery();
  const { pathname } = useLocation();
  const routePermission = permissions.find(({ route }) => pathname.startsWith(route));
  const isAuthorized = !routePermission || currentUser?.authenticatedUser.permissions.includes(routePermission.name);

  return (
    <Box>
      <Flex
        w="full"
        alignItems="center"
        gap="6"
        mb="6"
        pb="1"
        borderBottomWidth="1px"
        borderBottomColor={colorMode === 'dark' ? 'gray.700' : 'gray.300'}
        borderBottomStyle="solid"
      >
        <Box>
          <Link as={RouterLink} to="/" color={colorMode === 'dark' ? 'blue.400' : 'blue.600'}>
            <FontAwesomeIcon icon={faHouseBlank} />
          </Link>
        </Box>
        <Box flex="1">
          <PageHeading pt="1">{isAuthorized ? heading : 'Access Denied'}</PageHeading>
        </Box>
      </Flex>
      <Container maxW={fullWidth ? 'full' : 'container.xl'} p="0">
        <Box
          bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
          rounded="12"
          py="6"
          px={{ base: 6, lg: 12 }}
          boxShadow="xl"
        >
          {!isAuthorized && <Text align="center">You don&apos;t have access to this page.</Text>}
          {isAuthorized && children}
        </Box>
      </Container>
    </Box>
  );
}

ContentContainer.defaultProps = {
  children: undefined,
  fullWidth: false,
};

export default ContentContainer;
