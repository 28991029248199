import {
  VStack,
  Flex,
  Box,
  Spacer,
  List,
  ListItem,
  Heading,
  Divider,
  Link,
  useColorMode,
  StackProps,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCode,
  faBrowsers,
  faTerminal,
  faUsers,
  faRocketLaunch,
  faBasketShopping,
  faCrown,
  faLocationDot,
  faMoneyBillWave,
} from '@fortawesome/pro-duotone-svg-icons';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ColorModeToggle from '../colorMode/ColorModeToggle';
import SidebarMenuLink from './SidebarMenuLink';
import { useLoginQuery } from '../auth/authApi';
import { permissions } from '../../app/permissions';

interface ISidebarLink {
  to: string;
  icon: IconProp;
  label: string;
}

function SidebarMenu({ display }: StackProps) {
  const { pathname } = useLocation();
  const { colorMode } = useColorMode();
  const activeLinkBg = colorMode === 'dark' ? 'blue.900' : 'blue.200';
  const { data: currentUser } = useLoginQuery();

  const links: ISidebarLink[] = [
    { to: '/website', icon: faBrowsers, label: 'Websites' },
    { to: '/brand', icon: faBasketShopping, label: 'Brands' },
    { to: '/global-instant-rebate', icon: faMoneyBillWave, label: 'Instant Rebates' },
    { to: '/client-map', icon: faLocationDot, label: 'Client Map' },
    { to: '/site-user', icon: faUsers, label: 'Site Users' },
    { to: '/script', icon: faCode, label: 'Scripts' },
    { to: '/deployment', icon: faRocketLaunch, label: 'Deployments' },
    { to: '/system-user', icon: faCrown, label: 'System Users' },
  ];

  return (
    <VStack spacing="3" height="calc(100vh - 48px)" display={display}>
      <Box w="full">
        <List spacing="3">
          <ListItem>
            <Link
              as={RouterLink}
              to="/"
              textDecoration="none"
              css={css`
                &:hover {
                  text-decoration: none;
                }
              `}
            >
              <Flex align="center" justify="center" p="3" borderRadius="md">
                <Box color="blue.500" mr="2">
                  <FontAwesomeIcon icon={faTerminal} />
                </Box>
                <Heading as="h2" size="md" color="gray.500">
                  Terminal
                </Heading>
              </Flex>
            </Link>
          </ListItem>
          <ListItem>
            <Divider />
          </ListItem>
          {links
            .filter(({ to }) => {
              const routePermission = permissions.find(({ route }) => to.startsWith(route));
              const isAuthorized =
                !routePermission || currentUser?.authenticatedUser.permissions.includes(routePermission.name);
              return isAuthorized;
            })
            .map(({ to, icon, label }) => (
              <ListItem key={to}>
                <SidebarMenuLink
                  to={to}
                  leftIcon={<FontAwesomeIcon icon={icon} />}
                  bg={pathname.startsWith(to) ? activeLinkBg : undefined}
                >
                  {label}
                </SidebarMenuLink>
              </ListItem>
            ))}
        </List>
      </Box>
      <Spacer />
      <Divider />
      <Flex w="full" justify="flex-start">
        <Box>
          <ColorModeToggle />
        </Box>
      </Flex>
    </VStack>
  );
}

export default SidebarMenu;
