import { Heading, HeadingProps } from '@chakra-ui/react';

type IProps = HeadingProps;

function PageHeading({ as = 'h1', size = 'xl', pb = 3, children }: IProps) {
  return (
    <Heading as={as} size={size} pb={pb}>
      {children}
    </Heading>
  );
}

export default PageHeading;
