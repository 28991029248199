import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCreateGlobalInstantRebateMutation } from './globalInstantRebateApi';
import { IFormValues } from './types';
import GlobalInstantRebateForm from './GlobalInstantRebateForm';

function AddGlobalInstantRebate() {
  const [createGlobalInstantRebate] = useCreateGlobalInstantRebateMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (values: IFormValues) => {
    try {
      const res = await createGlobalInstantRebate(values).unwrap();
      const success = !!res.uuid;

      toast({
        title: success
          ? 'Successfully created the global instant rebate!'
          : 'Failed to create the global instant rebate!',
        status: success ? 'success' : 'error',
        isClosable: true,
        position: 'top',
      });

      if (success) {
        navigate(`/global-instant-rebate/${res.uuid}`);
      }
    } catch (error) {
      toast({
        title: 'Failed to create the global instant rebate!',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return <GlobalInstantRebateForm heading="Add Global Instant Rebate" onSubmit={onSubmit} />;
}

export default AddGlobalInstantRebate;
