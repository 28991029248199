import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import ContentContainer from '../layout/ContentContainer';
import AddSiteUserForm from './AddSiteUserForm';
import DeleteSiteUserForm from './DeleteSiteUserForm';

function SiteUsers() {
  return (
    <ContentContainer heading="Site Users">
      <Tabs>
        <TabList>
          <Tab>Add</Tab>
          <Tab>Delete</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AddSiteUserForm />
          </TabPanel>
          <TabPanel>
            <DeleteSiteUserForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ContentContainer>
  );
}

export default SiteUsers;
